<template>
    <div class="container">
        <!-- 在读本科生 -->
        <div>
            <div class="title-box3">
                <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                    src="../../static/image/icon.png"></el-image>
                <h2 style="width: 200px; margin-top: 0px;">在读本科生</h2>
            </div>
            <el-row :gutter="0">
                <el-col :span="8" v-for="item in students" :key="i">
                    <div class="infoBox">
                        <!-- 头像 -->
                        <!-- <el-avatar style="" shape="circle" :size="120" :src="item.imgurl" fit="cover"></el-avatar> -->
                        <el-image :src="item.imgurl" :fit="fits" class="avatar-box"></el-image>
                        <!-- 基础信息 -->
                        <div class="infos">
                            <p style="font-weight: bold; font-size: 18px;">{{ item.name }}</p>
                            <p style="margin-top: -5px; font-size: 15px;">{{ item.major }}</p>
                            <p style="margin-top: -5px; font-size: 15px;"></p>
                        </div>
                    </div>

                </el-col>
            </el-row>
        </div>
        <!-- 已毕业本科生 -->
        <div>
            <div class="title-box3">
                <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                    src="../../static/image/icon.png"></el-image>
                <h2 style="width: 200px; margin-top: 0px;">已毕业本科生</h2>
            </div>
            <ul style="list-style: disc;">
                <li v-for="item in graduatedStudents" :key="i">
                    <p>{{ item.value }}</p>
 
                </li>
            </ul>
            <!-- <el-row :gutter="0">
                <el-col :span="8" v-for="item in students" :key="i">
                    <div class="infoBox"> -->
            <!-- 头像 -->
            <!-- <el-avatar style="" shape="circle" :size="120" :src="item.imgurl" fit="cover"></el-avatar> -->
            <!-- <el-image :src="item.imgurl" :fit="fits" class="avatar-box"></el-image> -->
            <!-- 基础信息 -->
            <!-- <div class="infos">
                            <p style="font-weight: bold; font-size: 18px;">{{ item.name }}</p>
                            <p style="margin-top: -5px; font-size: 15px;">{{ item.major }}</p>
                            <p style="margin-top: -5px; font-size: 15px;"></p>
                        </div>
                    </div>

                </el-col>
            </el-row> -->
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            fits: "cover",
            squareUrl:
                "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
            students: [
                {
                    imgurl: "static/image/students/master/黄前顶.jpg",
                    name: "黄前顶",
                    major: "知识蒸馏",
                    achievement: '发表一区3篇'

                },
                {
                    imgurl: "../../static/image/students/undergraduate/张丁文.jpg",
                    name: "张丁文",
                    major: "KG，GNN，LLM",

                },
                {
                    imgurl: "../../static/image/students/undergraduate/洪晖阳.jpg",
                    name: "洪晖阳",
                    major: "靶向小分子生成",

                },
                {
                    imgurl: "../../static/image/students/undergraduate/邹子昂.jpg",
                    name: "邹子昂",
                    major: "基础视觉模型",

                },
                {
                    imgurl: "../../static/image/students/undergraduate/李疏影.jpg",
                    name: "李疏影",
                    major: "KG，GNN，LLM",

                },
                {
                    imgurl: "../../static/image/students/undergraduate/李赛博.jpg",
                    name: "李赛博",
                    major: "细粒度学习",

                },

            ],
            graduatedStudents: [
                { value: '吴晨阳，现在南开大学直博' },
                { value: '李梅燕，现在山东大学攻读硕士' },

            ]
        };
    },
    mounted() {
        // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
        // 子页面加载时触发事件
        this.$emit('changeHeight1', '100%');
    },
}
</script>
<style scoped>
.title-box3 {
    display: flex;
    width: 100%;
    margin-top: 15px;
}

.container {
    margin-left: -20px;
}

.infos {
    margin-left: 12px;
    margin-top: 5px;
}

.infoBox {
    margin-top: 15px;
    display: flex;
}

.avatar-box {
    height: 130px;
    width: 130px;
    border-radius: 50%;
}
</style>