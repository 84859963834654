<template>
    <div class="teacher">
        <!-- 头像和基本信息 -->
        <div class="teacher-baseinfo">
            <div>
                <el-image style="height: 200px; width: 166px;" :src="imageUrl"></el-image>
            </div>
            <div class="teacher-baseinfo-infobox">
                <div class="title-box3">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 100px; margin-top: 0px;">基本信息</h2>
                </div>
                <!-- 基本信息 -->
                <div class="teacher-baseinfo-content">
                    <ul>
                        <li v-for="item in basicInfo" :key="item.id" class="infos">
                            <span>{{ item.label }}</span>
                            <span>{{ item.value }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 个人简介 -->
        <div class="profile-box">
            <p>{{ profile }}</p>
        </div>
        <!-- 学习工作经历 -->
        <div class="experience-box">
            <div class="experience-infobox">
                <div class="title-box3">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">学习工作经历</h2>
                </div>
                <ul>
                    <li v-for="item in experiment" :key="item.id" class="infos">
                        <span>{{ item.label }}</span>
                        <span>{{ item.value }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 代表论文 -->
        <div class="paper-box">
            <div class="experience-infobox">
                <div class="title-box3">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">代表论文</h2>
                </div>
                <ul style="list-style-type: disc;">
                    <li v-for="item in papers" :key="item.id" class="infos">
                        <span>{{ item.value }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 科研项目 -->
        <div class="project-box">
            <div class="experience-infobox">
                <div class="title-box3">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">科研项目</h2>
                </div>
                <ol >
                    <li v-for="item in project" :key="item.id" class="infos">
                        <span>{{ item.value }}</span>
                    </li>
                </ol>
            </div>
        </div>
        <!-- 重要奖项 -->
        <div class="award-box">
            <div class="experience-infobox">
                <div class="title-box3">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">重要奖项</h2>
                </div>
                <ol >
                    <li v-for="item in award" :key="item.id" class="infos">
                        <span>{{ item.value }}</span>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'TeacherXiaoYuanYuan',
    data() {
        return {
            imageUrl: '../../static/image/teacher/XiaoYuanYuan.png',
            // 信息数据
            basicInfo: [
                { label: "单位和职称：", value: "贵州大学计算机科学与技术学院，校聘副教授" },
                { label: "办公地址：", value: "贵州省贵阳市花溪区贵州大学西校区崇厚楼" },
                { label: "主要研究方向：", value: "人工智能、计算机视觉、智慧农业、三维方向、多/高光谱方向" },
                { label: "电子邮箱：", value: "yyxiao@gzu.edu.cn" },
            ],
            experiment: [
                { label: "2005.03 ~ 现在：", value: "贵州大学，工作" },
                { label: "2013.09 ~ 2019.07：", value: "贵州省信息与计算科学重点实验室，导师：谢晓尧 "},

            ],
            papers: [
                { value: "肖源源 ; 谢晓尧 ; Application of Novel Gabor-DCNN into RGB-D Face Recognition,International Journal of Network Security, 2020, 22(3): 534-541(期刊论文)( 本人标注: 唯一第一作者 )" },
                { value: "肖源源 ; 谢晓尧 .基于RGB-D生物特征识别的安全认证[J].郑州大学学报(理学版), 2018, 50(04):29-33.DOI:10.13705/j.issn.1671-6841.2017295." },
                
            ],
            project: [
                { value:"贵州大学人才项目，校级项目，基于RGB-D目标识别算法研究,2022-01至2024-12，5万元,在研，主持；"},
                { value:"贵州大学，校级教改项目，数字图像处理课程思政研究，2024-01至2024-12，1万元,在研，主持；"},
                { value:"贵州省教育厅, 省级项目, 2021015, 软件工程课程思政研究, 2021-10 至 2023-10, 5万元, 结题, 主持；"},
                { value:"贵州大学, 校级项目, 软件工程课程思政探索与实践, 2020-7至 2022-7, 1万元, 结题, 主持"},
                { value:"贵州省科技厅, 贵州省自然科学基金, 黔科合LH[2014]7641, MANET多目标路由协议仿真与建模, 2014-12 至 2018-12, 5万元, 结题, 主持。"},
                { value:"贵州大学青年项目，贵州大学，虚拟校园景观建模与漫游实现，2万元，结题，主持。"},

            ],
            award:[
                { value:"2024年华为杯数学建模大赛，国赛三等奖。"},
                { value:"十四届“‌挑战杯”‌中国大学生创业计划竞赛，省赛优秀奖，校赛三等奖。"},
                { value:"第十八届“挑战杯”全国大学生课外学术科技作品竞赛，校赛二等奖"},
                { value:"第八届中国国际互联网+大学生创新创业大赛贵州大学校赛三等奖，省赛一等奖"},
            ],
            profile: "肖源源，校聘副教授，硕士生导师，目前就职于贵州大学计算机科学与技术学院，主持承担了贵州省自然科学基金一项（已结题），贵州大学人才项目一项，贵州大学青年项目一项，贵州省教育厅教改项目一项，贵州大学校级教改项目两项，获1项中国发明专利授权，2项软件著作权。指导本科生SRT项目多项。指导学生参加华为杯数学建模大赛获国家级三等奖，指导学生参加大学生创业计划竞赛省级优秀奖一项，校级获奖若干项；指导学生参加互联网+比赛获校赛三等奖、二等奖若干。"
        }
    }
}
</script>

<style>
.award-box{
    position: relative;
    top: 20px;
}
.project-box {
    position: relative;
    top: 20px;
}

.paper-box {
    position: relative;
    top: 20px;
}


.experience-box {
    position: relative;
    top: 20px;
}

.profile-box {
    position: relative;
    top: 20px;
    width: 100%;
}

.profile-box p {
    text-indent: 2em;
    line-height: 25px
}

.teacher-baseinfo-content {
    width: 100%;
}

.infos {
    margin-top: 10px;
}

ul {
    list-style-type: none;
}

.title-box3 {
    display: flex;
    width: 100%;
}

.teacher-baseinfo-infobox {
    position: relative;
    left: 33px;
}

.teacher-baseinfo {
    position: relative;
    display: flex;
    top: 30px;
    width: 100%;
}

.teacher {
    width: 89%;
}
</style>