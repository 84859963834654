<template>
    <div class="PlantPAD">
        <!-- 网站简介 -->
        <div class="profile-box">
            <!-- 使用 v-html 渲染 profile 的 HTML 内容，即能实现超链接的跳转 -->
            <p v-html="profile1"></p>
            <p v-html="profile2" style="margin-top: -10px;"></p>
            <p v-html="profile3" style="margin-top: -10px;"></p>

        </div>
        <!-- 图片部分  -->
        <div class="image-box">
            <el-image :src="imgurl" fit="cover"></el-image>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: 'PlantPAD',
            profile1: `PlantPAD（<a href="https://plantpad.samlab.cn/" target="_blank">https://plantpad.samlab.cn/</a>）是一个专注于植物健康与病害防治的专业平台，为植物学家、农民以及园艺爱好者提供全面的知识支持和创新解决方案。通过深入的科学探索和专业数据分析，我们为用户呈现关于植物病害的详尽信息和精美的相关图像。
        `,
            profile2: "我们的特色还包括提供先进的预训练模型，用于精准诊断植物病害，帮助用户快速识别问题，制定科学有效的解决方案。不论您是想了解病害的成因、传播机制，还是寻找适合的防治方法，我们的平台都能为您提供专业指导。同时，我们也致力于推广可持续农业理念，帮助农民保护作物健康、提升产量，并降低环境影响。",
            profile3: "通过这个平台，您不仅可以探索植物病害的奥秘，还能分享和学习关于植物健康管理的前沿技术和案例。我们相信，知识的力量能够让每一片绿叶茁壮成长，为地球的生态和农业生产带来无限可能。加入我们，一起探索植物健康的广阔世界！",
            imgurl: '../../static/image/webs/PlantPAD.png',
        }
    },
    methods: {
    },
    mounted() {
        // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
        // 子页面加载时触发事件
        this.$emit('changeHeight1', '100%');
    },
}
</script>

<style scoped>
.PlantPAD {
    width: 90%;
}

.profile-box {
    position: relative;
    top: 20px;
    width: 100%;
}

.profile-box p {
    font-size: 16px;
    
    text-indent: 2em;
    line-height: 25px
}

.image-box {
    margin-top: 50px;
}
</style>