<template>
  <div class="page-container">
    <!-- 顶部导航 -->
    <el-header class="header">
      <div class="logo"><img src="../../static/image/logo.png" alt="logo" class="logo_img">
        <div class="logo_text">SAMLAB</div>
      </div>
      <div class="nav-box">
        <el-menu :default-active="activeMenu" class="nav-menu" mode="horizontal" router>
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item @click="handleMenuSelect('/ResearchDirection')" index="/ResearchDirection">研究方向</el-menu-item>
          <el-menu-item @click="handleMenuSelect('/Members')" index="/Members">人员组成</el-menu-item>
          <el-menu-item @click="handleMenuSelect('/Projects')" index="/Projects">程序开发</el-menu-item>
          <el-menu-item @click="handleMenuSelect('/PaperPatent')" index="/PaperPatent">成果展示</el-menu-item>
          <!-- 这里不要了 -->
          <!-- <el-menu-item @click="handleMenuSelect('/Awards')" index="/Awards">获奖展示</el-menu-item>  -->
          <el-menu-item @click="handleMenuSelect('/Blogrolls')" index="/Blogrolls">友情链接</el-menu-item>
        </el-menu>
      </div>

    </el-header>


    <!-- Banner 区域 -->
    <section class="banner">
      <h1>智慧农业与多模态实验室</h1>
      <p>运用人工智能多模态技术，驱动现代农业数字化、智能化、绿色化转型，构建可持续发展的智慧农业技术与应用生态体系，成为引领全球智慧农业科技的核心力量。</p>
     
    </section>

    <!-- 欢迎部分 -->
    <section class="welcome-section">
      <div class="wenzi">
      <div class="wenzi1">
        <div class="xiaolian">
    <img src="../../static/image/homeviewIcons/图层 531.png" alt="Logo1">
    <div class="textxiaolian">
        欢迎访问SAMLAB实验室
    </div>
</div>
      <div class="biaoti">课题组致力于大数据与人工智能基础研究、植物智慧医学、人工智能分子设计、农业大数据智能应用</div>
      <div class="biaoti2">
  <span class="square1"></span>
  <span class="square2"></span>
  <span class="square3"></span>
  本课题组主要研究方向包括:
</div>
</div>
      <div class="wenzi2">
          <div class="image-text-container"><img   src="../../static/image/01.png" > <div class="text"><h1>(1)大数据与人工智能基础研究：</h1><div class="text1">目标检测、图像分割、图文描述、大模型视觉应用、图像生成、离散视觉表征、少样本学习、3D方向、模型轻量化、知识图谱.</div></div></div>
          <div class="image-text-container"><img  src="../../static/image/02.png" > <div class="text"><h1>(2)植物智慧医学：</h1><div class="text1">作物病害视觉感知、作物病害描述和诊断、农药数字化和智能化、大模型病害诊断和用药方案生成。</div></div></div>
          <div class="image-text-container"><img  src="../../static/image/03.png"> <div class="text"><h1>(3)人工智能分子设计：</h1><div class="text1">分子生成、大分子生成、小分子性质预测，分子-蛋白相互作用预测。</div></div></div>
          <div class="image-text-container"><img  src="../../static/image/05.png" > <div class="text"><h1>(4)农业大数据智能应用：</h1><div class="text1">高光谱和多光谱感知、农业视觉计数、田间三维检测、农业传感器和嵌入式移植。</div></div></div>
      </div>
      </div>
      <div class="tupian">
        <div class="leaf leaf1">
    <img src="../../static/image/homeviewIcons/-e-图层 524-3.png" alt="Leaf 1">
    <div class="textyepian">9<br><span>老师</span></div>
  </div>
  <div class="leaf leaf2">
    <img src="../../static/image/homeviewIcons/-e-图层 524-2.png" alt="Leaf 2">
    <div class="textyepian">15<br><span>科研项目</span></div>
  </div>
  <div class="leaf leaf3">
    <img src="../../static/image/homeviewIcons/-e-图层 524-1.png" alt="Leaf 3">
    <div class="textyepian">20+<br><span>专利数目</span></div>
  </div>
  <div class="leaf leaf4">
    <img src="../../static/image/homeviewIcons/-e-图层 524.png" alt="Leaf 4">
    <div class="textyepian">50+<br><span>发表论文数量</span></div>
  </div>
        </div>
</section>

<!-- 实验室介绍 -->
<section class="lab-info">
    <div class="lab-detailscontainer" >
       <div class="lab-details" >
      <h1>实验室介绍</h1>
      <h2>INTRODUCTION</h2>
      <div class="lab-text" >
      智慧农业与多模态实验室(Smart Agriculture and Multimodality Laboratory，简称SAMLab)，
      于2021年9月由王崎老师创建，隶属贵州大学，自成立起，团队主要聚焦人工智能多模态感知，
      并将其应用到农业相关任务。目前正依托公共大数据国家重点实验室和绿色农药全国重点实验室，
      贵州大学的两个国家级平台开展交叉方向研究。团队科研主要以多模态技术为核心，
      一方面开展纯计算机多模态感知方向研究，另一方面，与绿色农药全国重点实验室郝格非教授合作，
      开展针对农作物生产过程中的细分任务，如病害诊断与用药、田间作物表型监测、多源遥感监测等领域的科学研究，
      将开发的多模态方法应用到这些任务。目前团队发表SCI 1区论文30余篇，主持承担国家级及省部级项目15余项，
      获发明专利20余件，
      团队负责人王崎老师领衔的“复杂环境下农业病虫害的多模态大数据精准鉴定技术研究”获得了2024数博会十大领先科技成果。
      </div></div>
      <div class="lab-image">
      <img src="../../static/image/homeviewIcons/-e-图层 568.png" alt="相关图片描述">
      </div>
    </div>
  </section>

<!-- 新闻中心 -->
<section class="news-info">
  <div class="news-detailscontainer">
    <div class="news-details">
      <h1>新闻中心</h1>
      <h2>NEWS CENTER</h2>
    </div>
    <!-- Vue 组件挂载点 -->
    <div id="app">
      <ul class="news-list">
        <li v-for="(news, index) in newsData" :key="index" class="news-item">
          <div class="news-date">{{ news.date }}</div>
          <div class="news-content">{{ news.content }}</div>
        </li>
      </ul>
    </div>
  </div>
</section>




    <!-- 加入我们 -->
  <div class="join-us">
  <div class="content-with-image">
    <div class="text-content">
      <h1>加入我们</h1>
      <h2>JOIN US</h2>
      <h3>热烈欢迎对人工智能与智慧农业感兴趣的本科生、研究生、博士后加入王崎教授课题组。</h3>
      <router-link to="/Learnmore" class="learn-more-button">了解更多 >></router-link>
    </div>
  </div>
    <div class="block">
  <el-carousel height="580px" :interval="4000" arrow="always">
    <el-carousel-item v-for="(item, index) in images" :key="index">
      <img :src="item.src" :alt="item.alt" class="carousel-image">
    </el-carousel-item>
  </el-carousel>
</div>
  
</div>




    <!-- 联系信息 -->
  <section class="contact-info">
    <div class="contact-detailscontainer" >
       <div class="contact-details" >
      <h1>联系信息</h1>
      <h2>CONTACT INFORMATION</h2>
        <ul>
          <div class="contact-item"><img   src="../../static/image/iconlianxiren.png" > <div class="contact-text">联系人：王崎</div></div>
          <div class="contact-item"><img  src="../../static/image/icondizhi.png" > <div class="contact-text">实验室地址：贵州大学崇厚楼9楼</div></div>
         
          <div class="contact-item"><img  src="../../static/image/iconyouxiang.png" > <div class="contact-text">邮箱：qiwang@gzu.edu.cn</div></div>
        </ul>
      </div>
      <div class="map-image">
      <img src="../../static/image/map.png" alt="相关图片描述">
      </div>
    </div>
  </section>
<!-- 底部备案信息 -->
<div class="beian-block">
      <div class="nav-box2">
        <el-menu :default-active="activeMenu" class="nav-menu2" mode="horizontal" router>
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item @click="handleMenuSelect('/ResearchDirection')" index="/ResearchDirection">研究方向</el-menu-item>
          <el-menu-item @click="handleMenuSelect('/Members')" index="/Members">人员组成</el-menu-item>
          <el-menu-item @click="handleMenuSelect('/Projects')" index="/Projects">程序开发</el-menu-item>
          <el-menu-item @click="handleMenuSelect('/PaperPatent')" index="/PaperPatent">成果展示</el-menu-item>
          <el-menu-item @click="handleMenuSelect('/Blogrolls')" index="/Blogrolls">友情链接</el-menu-item>
        </el-menu>
      </div>

      <div class="a-block">
        <a href="https://beian.miit.gov.cn/" target="_blank">黔ICP备2022008995号-1</a>
      </div>


    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      activeMenu: "1",
      images: [
        { src: require('../../static/image/hezhao/合照1.jpg'), alt: '合照1' },
        { src: require('../../static/image/hezhao/合照2.jpg'), alt: '合照2' },
        { src: require('../../static/image/hezhao/合照3.jpg'), alt: '合照3' },
        { src: require('../../static/image/hezhao/合照4.jpg'), alt: '合照4' },
        { src: require('../../static/image/hezhao/合照5.jpg'), alt: '合照5' },
        { src: require('../../static/image/hezhao/合照6.jpg'), alt: '合照6' }
      ],
      // 添加新闻数据
      newsData: [
        { date: '2025.01.06', content: '吴兴财的论文投稿 Information Processing in Agriculture' },
        { date: '2024.12.30', content: '谢雨珊的论文投稿 Artificial Intelligence in Agriculture' },
        { date: '2024.12.30', content: '周勇军的论文投稿 Applied Intelligence' },
        { date: '2024.12.23', content: '张丁文的论文投稿 Artificial Intelligence in Agriculture' },
        { date: '2024.12.23', content: '淡玉娇的论文投稿 Computers andEleetronics in Agriculture' },
        { date: '2024.12.16', content: '陈国军的论文被 Knowledge Based Systems接收' },
        { date: '2024.12.16', content: '余娅的论文投稿 Plant Phenomics' },
        { date: '2024.11.11', content: '陈国军的论文被 Data Science and Engineering接收' },
        { date: '2024.10.28', content: '陈国军的专利:《基于语义及关联建模进行实体及提及增强的关系抽取方法》授权' },
        { date: '2024.10.28', content: '陈国军的论文被Data Science and Engineering接收' },
        { date: '2024.10.14', content: '董新宇、万潇阳、刘阳、王玉祥、淡玉娇的省创新基金项目成功被推荐至省级' },
        { date: '2024.10.08', content: '张家伟的论文投稿Artificial ntelligence In Agriculture' },
        { date: '2024.09.09', content: '姜思君的论文被Plant Phenomics接收' },
        { date: '2024.09.09', content: '李耀羲的论文被Plant Phenomics接收' },
        { date: '2024.09.01', content: '李青雷、赵克君，获得贵州大学特等奖学金' }
      ],
      showMore: false
    };
  },
  methods: {
    showMoreNews() {
      this.showMore = !this.showMore;
    }
  }
};
</script>

<style scoped>
.page-container {
  font-family: Arial, sans-serif;
  color: #333;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  background-color: rgba(255, 255, 255, 0);
/* 半透明背景 */
  position: absolute;
 /* 使导航栏固定在顶部 */
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  /* 确保在图片之上 */
 border: none;
 box-shadow: none;
 outline: none;
}

.logo{
  padding-left: 10%;
  display: flex;
  align-items: center; /* 垂直居中 */
}
.logo_img{
  width: 100px;
  margin-top: 35px;
  margin-right: -20px;
}
.logo_text {
  margin-left: 28px; /* 调整此值以增加或减少图片和文字之间的距离 */
  font-family: STHeiti;
  font-size: 25px;
  font-weight: bold;
  margin-top: 20px;
}

.nav-menu {
 flex: 1;
 text-align: left;
 align-items: flex-end;
 background-color: rgba(241, 231, 231, 0);
 /* 半透明背景 */
 border: none;
 box-shadow: none;
 outline: none;
 /* 去掉菜单的边框 */
 
 

}
.nav-box {
  margin-right: 200px;
  font-size: 50xp;
  border: 0px;
}
.nav-menu .el-menu-item {
  background-color: rgb(0 0 0 / 0%);
  /* 菜单项背景透明 */
  color: black;
  /* 文字颜色，根据背景调整 */
  border: 0px;
}

.nav-menu .el-menu-item.is-active {
  color: #7c9663;
  /* 激活项颜色 */
  background-color: rgb(0 0 0 / 0%);
  /* 菜单项背景透明 */
  border: 0px;
}

.banner {
  text-align: center;
  background-image: url("../../static/image/首页banner@2x.png");
  background-size: cover;
  /* 让背景图片覆盖整个元素 */
  background-position: center;
  padding: 305px ;
  padding-left: 15%;
  
}


.welcome-section {
  position: relative;
  display: flex;
  justify-content: space-around;
  background-image: url('../../static/image/homeviewIcons/-e-图层-523.png'); 
  width: 100%;
  height: 1000px;
  background-size: cover;
}
.wenzi {
  width: 80%; /* 设置容器宽度为父容器宽度的80% */
  max-width: 1000px; /* 最大宽度不超过1200px */
  margin: 0 auto; /* 居中显示 */
  /* 文本水平居中 */
  text-align: center;

  /* 如果内部有多个块级元素（如div），并且你想要它们在容器中水平排列和居中 */
 
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  flex-direction: column; /* 子元素垂直排列，如果你想让它们水平排列，可以将此值改为row */
}
.wenzi1{
  width: 100%;
  height: 30%; /* 高度为父容器高度的30% */
  text-align: left;
  margin-top: 60px;
  padding-left: 2%;
}
.wenzi2{
  width: 100%;
  text-align: left;
  margin-top: -30px;
  
}
.xiaolian {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  gap: 20px; /* 图片和文字之间的间距 */
}

.xiaolian img {
  width: 50px; /* 调整图片宽度 */
  height: auto; /* 保持图片比例 */
}

.xiaolian .textxiaolian {
  font-size: 50px;
  white-space: nowrap; /* 确保文字在一行内显示 */
  overflow: hidden; /* 隐藏超出部分的文字 */
  text-overflow: ellipsis; /* 显示省略号 */
}
.biaoti{
  font-size: 30px;
  margin: 23px;
  line-height: 1.8; /* 设置行距为字体大小的1.5倍 */
}
.biaoti2 {
  font-size: 30px;
  margin: 20px;
  
  line-height: 1.5; /* 设置行距为字体大小的1.5倍 */
  color: #177743; /* 设置文字颜色为深灰色 */
}

.square {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #FF9A4B; /* 设置正方形背景颜色为橙色 */
  margin-right: 10px; /* 正方形和文字之间的间距 */
}
.square1 {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #FF9A4B; /* 设置正方形背景颜色为橙色 */
  margin-right: 10px; /* 正方形和文字之间的间距 */
}
.square2 {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #fbbc76; /* 设置正方形背景颜色为橙色 */
  margin-right: 10px; /* 正方形和文字之间的间距 */
}
.square3 {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #fbddb5; /* 设置正方形背景颜色为橙色 */
  margin-right: 10px; /* 正方形和文字之间的间距 */
}
.image-text-container {
  display: flex;
  align-items: center; /* 垂直居中 */
  margin: 30px;
 
}

.text {
  margin-left: 28px; /* 调整此值以增加或减少图片和文字之间的距离 */
  font-size: 80px; /* 单位应该是 'px' */
}
.text1{
  font-size: 20px;
}
.tupian {
  display: flex;
  flex-direction: column; /* 设置为竖向排列 */
  position: relative; /* 使用相对定位作为容器 */
  transform: translate(-200px, 100px);
}
.leaf {
  position: relative;
  width: 450px;
  
  margin-bottom: -60px; /* 根据需要调整此值使图片重叠 */
}
.leaf img {
  width: 100%;
  height: auto;
}
.textyepian {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2em;
  text-align: center;

}
.leaf1 .textyepian {
  font-size: 2.5em;
  
}

.leaf2 .textyepian {
  font-size: 2.4em;
}

.leaf3 .textyepian {
  font-size: 2.4em;
}

.leaf4 .textyepian {
  font-size: 2.3em;
}

.lab-detailscontainer {
  display: flex;
  justify-content: space-between;

  text-align: left;
  padding-left: 15%;

  padding: 20px;
}
.lab-details {
  padding-left: 13%;
}

.lab-info h1{

  padding: 20px;
  font-size: 50px;
  margin-top: 30px;
  
}
.lab-info h2{

  padding: 20px;
  font-size: 60px;
  margin-top: -40px;
 
  color:#dbe3db;
}

.lab-text{
  margin-left: 28px;
  font-size: 25px;
  margin-top: -30px;
  text-align: justify;
  font-weight :normal;
  line-height: 1.6; /* 设置行间距为字体大小的1.6倍 */
  width: 780px; /* 或者使用具体数值如1000px */
}
.lab-image img{
    margin-right: 150px; /* 图片与文字之间的间距 */
    margin-top: 150px;
    width: 600px; /* 调整图片的宽度 */
    height: 500px; /* 调整图片的高度 */
    margin-left: 100px;
  }


.join-us {
  
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  margin: 70px 0;
  text-align: left;
  padding-left: 15%;
  padding-right: 15%;
  background-color:#f5fbf8;
 
  height:750px; /* 设置固定高度 */
}
.join-us h1{
  font-size: 50px;

  margin-top: -100px;
}
.join-us h2{
  font-size: 100px;
  margin: 100px 0; /* 上下间距增加到150px，左右保持默认 */
  opacity: 0.1;
  padding-left: 15%;
}
.join-us h3{
  
  margin: 40px 0; /* 上下间距增加到150px，左右保持默认 */
  
 
}

.learn-more-button {
  display: inline-block;
  padding: 20px 30px;
  background-color:#fb9220; /* 按钮背景颜色 */
  color: #ffffff; /* 文本颜色 */
  font-size: 18px; /* 字体大小 */
  text-decoration: none; /* 移除下划线 */
  border-radius: 5px; /* 圆角边框 */
  transition: all 0.3s ease; /* 平滑过渡效果 */
  position: relative; /* 相对定位 */
  overflow: hidden; /* 隐藏溢出内容 */
}

/* 添加伪元素来创建悬停效果 */
.learn-more-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.4);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  transition: all 0.3s ease;
}


.learn-more-button:hover::before {
  width: 200%; /* 增加宽度 */
  height: 300%; /* 增加高度 */
}
.block {
  width: 1000px ;/* 确保块级元素占据80%的宽度 */
  height:500px; /* 设置固定高度 */
  margin-left: 10%;
  margin-top: -100px;
  margin-right: -50px;
}
/* 走马灯 */
.el-carousel__item h3 {
  color: #475669;
  font-size: 60px; /* 调整为更合理的字体大小 */
  opacity: 0.2;
  line-height: 100px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.carousel-image {
  width: 100%;
  height: 100%;

}
  .el-carousel__item:nth-child(2n) {
     background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
     background-color: #d3dce6;
  }
  .image-content1 img {
  
    height: auto; /* 保持图片原始比例 */
    
  }
.learn-more-button {
  margin-top: 20px;
}

.text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

.news-info{
  margin-left: -3%; /* 根据需要调整此值 */
  margin-top: 70px;
}
.news-detailscontainer {
  justify-content: space-between;
  text-align: left;
  padding: 10px;
}

.news-details {
  margin-bottom: 20px;
  text-align: left;
  margin-left: 18%;
}

.news-details h1 {
  font-size: 50px;
  margin-top: 30px;
}

.news-details h2 {
  font-size: 60px;
  color: #dbe3db;
  margin-top: 40px;
}

.news-list {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: 15%;
  gap: 20px; /* 调整项目之间的间距 */
  max-height: 500px; /* 设置一个固定的最大高度以启用滚动 */
  overflow-y: auto; /* 启用垂直滚动 */
  padding-right: 5%; /* 添加右边距以防内容被滚动条遮挡 */
}

.news-item {
  width: calc(50% - 10px); /* 每个新闻项占据一半宽度减去间距 */
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  align-items: center;
}

.news-date {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  font-size: 20px;
  color: #888;
  width: 100px; /* 设置一个固定宽度以确保日期框不会过长 */
  min-width: 120px; /* 确保最小宽度一致 */
}

.news-content {
  margin-left: 20px;
  font-size: 20px;
  line-height: 1.8; /* 增加行高以容纳更多文字 */
  flex-grow: 1; /* 让内容区域可以扩展填充剩余空间 */
}






.contact-detailscontainer {
  display: flex;
  justify-content: space-between;

  text-align: left;
  padding-left: 15%;

  padding: 20px;
}
.contact-details {
  padding-left: 13%;
}
.contact-info h1{

  padding: 20px;
  font-size: 50px;
  margin-top: 70px;
  
}
.contact-info h2{

  padding: 20px;
  font-size: 40px;
  margin-top: 40px;
  opacity: 0.1;
}
.contact-item {
  display: flex;
  align-items: center;
  padding: 30px;
  
}
.contact-text{
  margin-left: 28px;
}
.map-image img{
    margin-right: 150px; /* 图片与文字之间的间距 */
    margin-top: 10px;
    width: 800px; /* 调整图片的宽度 */
    height: 700px; /* 调整图片的高度 */
  }

.banner h1 ,p{
  text-align: left;
  
  padding-right: 30%;
  /* margin-left: 15%; */
}
.banner h1{
  font-size: 5rem;
  color:#567a54;
  margin-top: -40px;
  
}
.banner p{
  margin-top: 80px;
  font-size: 1.9rem;
}


.grid-container {
   display: grid;

    grid-template-columns: repeat(2, 1fr); /* 2 列，每列等宽 */
    grid-template-rows: repeat(2, 1fr); /* 2 行，每行等高 */
    gap: 0px; /* 网格项之间的间隔 */
    width: 100%; /* 宽度可以根据需要调整 */
    max-width: 600px; /* 最大宽度 */
    background-color: #fff;
    /* padding: 10px; */
    box-sizing: border-box;


}

.grid-item {
    /* height: 100px; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}
.grid-item h3{
  color: #7c9663;
}
.beian-block {
  background-color: #f8f8fa;
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  /* 设置为垂直排列 */
  justify-content: center;
  /* 可选：让子元素在垂直方向上居中对齐 */

  /* margin: 0 auto; */
}

.a-block {
  margin: 0 auto;

}

.a-block a {
  text-decoration: none;
  color: #909399;
  padding-top: 30px;
  font-size: 14px;
}

.a-block a:hover {
  color: #175199;
}

.nav-box2 {
  margin: 0 auto;

}

.nav-menu2 {
  flex: 1;
  text-align: left;
  align-items: flex-end;
  background-color: rgba(241, 231, 231, 0);
  /* 半透明背景 */
  border: none;
  box-shadow: none;
  outline: none;
  font-size: 14px;
  /* 去掉菜单的边框 */

}


</style>
