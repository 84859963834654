<template>
    <div>
        <!-- 合作组 -->
        <div class="experience-box">
            <div class="experience-infobox">
                <!-- 现在更改为表格展示数据 -->
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-table :data="cooperationsLeft" style="width: 361px;" stripe="true" border="true">
                            <el-table-column align="center" prop="name" label="名字" width="180" />
                            <el-table-column align="center" prop="college" label="学校" width="180" />
                        </el-table>
                    </el-col>
                    <el-col :span="12">
                        <el-table :data="cooperationsRight" style="width: 361px;" stripe="true" border="true">
                            <el-table-column align="center" prop="name" label="名字" width="180" />
                            <el-table-column align="center" prop="college" label="学校" width="180" />
                        </el-table>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            cooperationsLeft: [

                {
                    name: "陆雪松",
                    college: "华东师范大学"
                },
                {
                    name: "金时超",
                    college: "南京农业大学"
                },
                {
                    name: "金剑",
                    college: "普渡大学"
                },
                {
                    name: "王晓",
                    college: "武汉科技大学"
                },
                {
                    name: "王以松",
                    college: "贵州大学"
                },
                {
                    name: "王喜宾",
                    college: "贵州理工学院"
                },
                {
                    name: "夏道勋",
                    college: "贵州师范大学"
                },
                {
                    name: "易冉",
                    college: "上海交通大学"
                },
                {
                    name: "余婧雯",
                    college: "天津大学"
                },
                {
                    name: "姚小军",
                    college: "澳门理工大学"
                },
                {
                    name: "杨婧",
                    college: "中科院计算技术研究生"
                },
                {
                    name: "周济",
                    college: "英国剑桥作物中心"
                },
                {
                    name: "周锦程",
                    college: "黔南师范学院"
                },
                {
                    name: "Luc Cleasen",
                    college: "哈瑟尔特大学"
                },

            ],
            cooperationsRight:[
            {
                    name: "陈使明",
                    college: "华中科技大学"
                },
                {
                    name: "陈伟玲",
                    college: "中央电教馆"
                },
                {
                    name: "岑海燕",
                    college: "浙江大学"
                },
                {
                    name: "柴云鹏",
                    college: "中国人民大学"
                },
                {
                    name: "程勇",
                    college: "南京信息工程大学"
                },
                {
                    name: "崔忠伟",
                    college: "贵州师范学院"
                },
                {
                    name: "郭颖",
                    college: "北方工业大学"
                },
                {
                    name: "郭威",
                    college: "东京大学"
                },
                {
                    name: "刘武",
                    college: "中国科技大学"
                },
                {
                    name: "刘永进",
                    college: "清华大学"
                },
                {
                    name: "罗杰波",
                    college: "罗切斯特大学"
                },
                {
                    name: "雷亮",
                    college: "广东工业大学"
                },
                {
                    name: "李晖",
                    college: "贵州大学"
                },
                {
                    name: "李直旭",
                    college: "中国人民大学"
                },
            ],
        }
    },
    mounted() {
        // 更改el-aside的长度
        this.$emit('changeHeight1', '100%');
        // 将数据分成两部分
        const middleIndex = Math.ceil(this.cooperations.length / 2);
        this.cooperationsLeft = this.cooperations.slice(0, middleIndex);
        this.cooperationsRight = this.cooperations.slice(middleIndex);
    },
}
</script>
<style scoped>
.experience-infobox {
    width: 80%;
    margin-top: 20px;
    margin-left: 5%;
}

.infos {
    margin-top: 6px;

}

/* 使用深度选择器设置特定el-table的字体大小 */
.experience-box >>> .el-table th, .experience-box >>> .el-table td {
    font-size: 15px; /* 你可以根据需要调整这个值 */
}
</style>