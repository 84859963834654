<template>
    <div class="patents">
        <!-- 专利块 -->
        <div>

            <ul style="list-style-type: none;">
                <ol v-for="(item,index) in patents" :key="item.id" class="infos">
                    <span class="custom-counter">{{ patents.length-index + '. ' }}</span>
                    <span>{{ item.value }}</span>
                </ol>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Patents',
    data() {
        return {
            patents:[
                {value:'王崎，裴之蕈，等；发明实审：一种基于可见光图像的农业多光谱视觉重构方法；2022.10.28，CN202211329001.1'},
                {value:'王崎，黄前顶，等；发明授权：一种轻量化植物病虫害目标检测模型的构建和训练方法；2024.02.20，ZL202210769212.0'},
                {value:'王崎，吴兴财，等；发明授权：基于多粒度图文语义学习的文本引导图像修复模型及方法；2023.09.19，ZL202310796408.3'},
                {value:'王崎，李青雷，等；发明授权：一种基于特征向量的离散相似度匹配分类方法；2023.09.05，ZL202310756831.0'},
                {value:'张天瀚，王崎，等；发明授权：一种复杂场景下的作物三维目标检测方法；2023.09.01，ZL202310791383.8'},
                {value:'邓宏宇，王崎，等；发明授权：一种基于窗口自注意与多尺度机制的图像密集描述方法；2023.09.26，ZL202310822911.1'},
                {value:'王崎，吴晨阳，等；发明授权：一种基于小规模参数矩阵计算特征图边界的重构方法；2023.05.26，ZL202210229602.9'},
                {value:'王崎，颜润明，等；发明授权：一种基于通用振镜与视觉测速的飞行打标系统和控制方法；2023.09.15，ZL201710736104.2'},
                {value:'刘文印，王崎，等；发明授权：一种视频关键帧提取方法、系统及设备和存储介质；2023.07.28, ZL201810523467.2.'},
                {value:'刘文印，王崎，等；一种目标监测方法、系统及设备和存储介质;2024.02.20, ZL201810522083.9.'},
                {value:'肖源源，黄杨，王崎，等；一种基于多模态图像融合的杂草侵扰率预测方法，2024.05.22，ZL202410636283.2'},
                {value:'张邦梅，李耀羲，王崎，等；一种基于数量监督的农作物计数方法；2024.5.21，ZL202410629145.1'},
                {value:'陈攀峰，赵克君，王崎，等；一种基于语义保留与重构的图像文本控制描述生成方法；2024.5.24，ZL202410651350.8'},
                {value:'颜润明，王崎，等；一种在线视觉引导对位的激光打标系统和激光打标方法；2018.04.13，ZL201721066651.6.'},
                {value:'赖锦祥，钟嘉华，王崎，等；基于特征点匹配的自动测量系统及测量方法；2019.03.05，ZL201610407110.9.'},
                {value:'颜润明，雷亮，王崎，等；多尺度双轴旋转激光影像三维重构系统及其方法；2018.09.25，ZL201510980774.X.'},
                {value:'王建军，王崎，张邦梅，等；发明专利：一种基于对比学习的少样本细粒度图像分类方法，2023.09.19，ZL202310878846.4'},
                {value:'王崎，张家伟，吴雪，王亚洲，等；发明专利：一种基于图文子空间联合学习的植物叶片表型病害分类方法，2024.04.09，ZL202410067314.7'},
                {value:'陈攀峰，陈国军，王崎，等；发明专利：语基于语义及关联建模进行实体及提及增强的关系抽取方法，2024.10.01，ZL202411047703.X'},


            ]
        }
    },
    methods: {

    },
    mounted() {
        // 子页面加载时触发事件
        this.$emit('changeHeight', '100%');
    },

}


</script>
<style scoped>
.patents{
    width: 90%;
    margin-left: -45px;
}
.title-box3 {
    display: flex;
    width: 100%;
    margin-top: 15px;
}

.infos {
    margin-top: 10px;
}
.custom-counter {
  font-weight: bold; /* 加粗 */
}
</style>
