<template>
    <div class="direction1">
        <div style="color: #BCBCBC; font-size: 40px;position: relative;top: 20px;">{{ TileName }}</div>
        <div class="line"></div>
        <!-- 标题块  -->
        <div class="nameBox">
            <div class="numBox">
                <!-- <h3>1</h3> -->
                <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                    src="../../static/image/iconss/chemisty.png"></el-image>
            </div>
            <div class="titleBox">
                <h3 style="position: relative;left: 23px;">人工智能分子设计</h3>
            </div>
        </div>
        <!-- 内容块 -->
        <div>
            <div class="contentBox">
                <p>人工智能分子设计是一个融合语言大模型、图学习等前沿AI技术与生命科学的创新研究方向。通过深度生成模型、图神经网络等算法，该技术在小分子化合物和生物大分子两大领域实现智能化设计和优化，可以大幅提升研发效率，降低成本。在医药领域，它能设计高活性低毒性的候选药物；在农业领域，可开发环境友好型农药；在材料领域，可设计特定功能分子。随着计算能力提升和算法进步，这一技术将为解决人类面临的健康、环境、能源等重大挑战提供强有力的技术支撑。
                </p>

                <p>
                    团队研究内容：小分子药物及农药生成设计、生物农药设计、多组学知识图谱挖掘及把表发现
                </p>
            </div>

            <!-- 代表性结果块 -->
            <div class="Representative-Box">

                <div class="title-box3">
                    <el-image style="height: 20px; width: 20px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h3 style="width: 300px; margin-top: 0px;">实验室代表性成果：</h3>
                </div>

                <ol>
                    <li v-for="item in representativeResults" :key="item.id">{{ item.value }}</li>
                </ol>
            </div>
            <el-image class="imageBox" style="width: 100%;" src="static\image\Direction3.2.png" fit="cover" />
        </div>


    </div>

</template>



<script>
export default {
    data() {
        return {
            TileName: 'Research Direction',
            researchItems: [
                { id: 1, title: "人工智能分子设计" }
            ],
            representativeResults: [
                { value: "Yuquan Li, Chang-Yu Hsieh, Ruiqiang Lu, Xiaoqing Gong, Xiaorui Wang, Pengyong Li, Shuo Liu, Yanan Tian, Dejun Jiang, Jiaxian Yan, Qifeng Bai, Huanxiang Liu, Shengyu Zhang & Xiaojun Yao. An adaptive graph learning method for automated molecular interactions and properties predictions. Nature Machine Intelligence 4.7 (2022): 645-651." },
                { value: "Yuquan Li, Pengyong Li, Xing Yang, Chang-Yu Hsieh, Shengyu Zhang, Xiaorui Wang, Ruiqiang Lu, Huanxiang Liu, Xiaojun Yao. Introducing block design in graph neural networks for molecular properties prediction. Chemical Engineering Journal 414 (2021): 128817." },
                { value: "Pengyong Li, Yuquan Li, Chang-Yu Hsieh, Shengyu Zhang, Xianggen Liu, Huanxiang Liu, Sen Song, Xiaojun Yao. TrimNet: learning molecular representation from triplet messages for biomedicine. Briefings in Bioinformatics 22.4 (2021): bbaa266. " },

            ]
        }
    },
    mounted() {
        // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
        // 子页面加载时触发事件
        this.$emit('changeHeight1', '130%');
    },
}
</script>
<style scoped>
.title-box3 {
    display: flex;
    width: 100%;
    margin-bottom: -30px;
    margin-top: 10px;
}

.Representative-Box {
    position: relative;
    top: 60px;
    width: 95%;
    /* left: 1%; */

}

.Representative-Box ol {
    list-style-type: decimal;
    /* padding: 0px;
    margin: 0px; */
}

.Representative-Box ol li {
    line-height: 24px;
}

.imageBox {
    position: relative;
    top: 60px;
    left: 2%;
}

.contentBox {
    position: relative;
    top: 60px;

}

.contentBox p {
    font-weight: 500;
    text-align: left;
    text-indent: 2em;
    padding: 0px;
    margin: 0px;
    line-height: 30px;

}

.nameBox {
    display: flex;
    position: relative;
    top: 49px;

}

.direction1 {
    width: 90%;
}

.line {
    position: relative;
    top: 30px;
    bottom: 32.5px;
    border: 0.1px solid #BCBCBC;
    width: 100%;
}

.numBox {
    width: 67px;
    height: 67px;
    background-color: #D4DFEB;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #2448FF;

}

.titleBox {
    height: 67px;
    width: 100%;
    font-size: 17px;
    color: #2E5A2D;
    background-color: #F7F9FA;
    justify-content: center;
    align-items: center;

}
</style>