<template>
    <div class="Doctor">
        <div v-for="i in docters" :key="i">
            <!-- 头像和基本信息 -->
            <div class="docter-baseinfo">
                <div>
                    <el-image style="height: 200px; width: 166px;" :src="i.imgURl"></el-image>
                </div>
                <div class="docter-baseinfo-infobox">
                    <div class="title-box1">
                        <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                            src="../../static/image/icon.png"></el-image>
                        <h2 style="width: 100px; margin-top: 0px;">基本信息</h2>
                    </div>
                    <!-- 基本信息 -->
                    <div class="docter-baseinfo-content">
                        <ul>
                            <li class="infoss">
                                <span>{{ i.nema }}</span>
                            </li>
                            <!-- 个人简介 -->
                            <li class="profile-box">{{ i.profile }}</li>
                        </ul>
                    </div>


                </div>
            </div>
            <!-- 重要学术奖项 -->
            <div class="paper-box">
                <div class="experience-infobox">
                    <div class="title-box3">
                        <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                            src="../../static/image/icon.png"></el-image>
                        <h2 style="width: 150px; margin-top: 0px;">重要奖项</h2>
                    </div>
                    <ul style="list-style-type: disc;">
                        <li v-for="item in i.awards" :key="item.id" class="infos">
                            <span>{{ item.value }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 代表论文 -->
            <div class="paper-box">
                <div class="experience-infobox">
                    <div class="title-box3">
                        <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                            src="../../static/image/icon.png"></el-image>
                        <h2 style="width: 150px; margin-top: 0px;">代表论文</h2>
                    </div>
                    <ul style="list-style-type: disc;">
                        <li v-for="item in i.papers" :key="item.id" class="infos">
                            <span>{{ item.value }}</span>
                        </li>
                    </ul>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            imageUrl: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
            docters: [
                {
                    imgURl: '../../static/image/students/doctor/吴雪.png',
                    nema: '吴雪',
                    profile: '现为贵州大学精细化工研究开发中心在读博士研究生，指导教师为郝格非教授。截至目前，已发表学术论文两篇，其中一篇收录于SCI一区期刊，另一篇发表于新刊。此外，作为共同第一作者，在新刊上发表了一篇highlight。研究方向主要聚焦于植物病虫害智能诊断及农药知识图谱的构建。通过融合信息技术与农业科学，致力于提升病虫害诊断的精准度和效率，同时为农药的科学合理使用提供数据支持和技术保障。',
                    awards: [
                        { value: "2023年：荣获博士研究生一等奖学金" },
                        { value: "2024年：荣获博士研究生二等奖学金" },

                    ],
                    papers: [
                        { value: "Xue Wu, Hongyu Deng, Qi Wang, YangYang Gao, Liang Lei, Ge-Fei Hao. Meta-learning Shows Great Potential in Plant Diseases Recognition Under Few Available Samples. Plant Journal. 2023, 03." },
                        { value: "Xue Wu, Daohong Gong, Kejun Zhao, Dongyu Chen, Yangyang Gao, Qi Wang, Yawen Dong Gefei Hao. Research and development trends in plant growth regulators. Advanced Agrochem, 2023.11." },
                    ]
                },
                {
                    imgURl: '../../static/image/students/doctor/吴兴财.jpg',
                    nema: '吴兴财',
                    profile: '人工智能研究方向，已发表10篇论文，其中以第一作者发表一篇SCI一区论文和一篇计算机顶会论文。发明专利6项。获得博士国家奖学金、硕士国家奖学金、多项学业奖学金，国家级及以上竞赛奖3项，省级3项，校级3项。',
                    awards: [
                        { value: "博士后创新创业大赛全国银奖" },
                        { value: "“互联网 +”大学生创新创业大赛，全国银奖" },
                        { value: "“华为杯”第二十届中国研究生数学建模竞赛，全国三等奖" },
                        { value: "“挑战杯”大学生课外学术科技作品竞赛，省一等奖" },
                        { value: "2023睿抗机器人开发者大赛（RAICOM) ，省赛三等奖" },

                    ],
                    papers: [
                        { value: "Xingcai Wu, Kejun Zhao et al. MISL: Multi-grained image-text semantic learning for text-guided image inpainting. Pattern Recognition.（第一作者，SCI一区top，2024）" },
                        { value: "Xingcai Wu, Xie Yucheng et al. Adversarial Learning with Mask Reconstruction for Text-Guided Image Inpainting. Proceedings of the 29th ACM International Conference on Multimedia.（第一作者，CCF A类会议，2021）" },
                        { value: "Qianding Huang, Xingcai Wu et al. Knowledge Distillation Facilitates the Lightweight and Efficient of Plant Diseases Detection Model. Plant Phenomics.（共同一作，SCI一区top，2023）" },
                        { value: "Xinyu Dong, Kejun Zhao, Qi Wang, Xingcai Wu et al. PlantPAD: a platform for large-scale image phenomics analysis of disease in plant science. Nucleic Acids Research.（共同一作，SCI二区，2023）" },
                        { value: "Kejun Zhao，Xingcai Wu et al. PlanText: Gradually Masked Guidance to Align Image Phenotype with Trait Description for Plant Disease Texts. Plant Phenomics. （第二作者，SCI一区top，2023）" },
                    ]
                },
                {
                    imgURl: '../../static/image/students/doctor/董新宇.png',
                    nema: '董新宇',
                    profile: '以第一作者发表2篇学术论文，Plant Phenomics（SCI 1区 Top）和NAR（SCI 1区 Top）各1篇，获得各种学科类竞赛奖励若干。',
                    awards: [
                        { value: "挑战杯全国特等奖" },
                        { value: "博士后创新创业大赛全国银奖" },
                        { value: "数学建模全国三等奖" },
                        { value: "挑战杯一带一路专项赛全国三等奖" },
                    ],
                    papers: [
                        { value: "Xinyu Dong, Kejun Zhao, Qi Wang*, Xingcai Wu, Yuanqin Huang, et al. PlantPAD: a platform for large-scale image phenomics analysis of disease in plant science. Nucleic Acids Research. 2023, gkad917." },
                        { value: "Xinyu Dong, Qi Wang*, Qianding Huang, Qinglong Ge, Kejun Zhao, Xingcai Wu, Xue Wu, Liang Lei, and Gefei Hao. PDDD-PreTrain: A series of common-used pre-trained models support image-based plant disease diagnosis. Plant Phenomics, 2023, 5:0054." },
                    ]
                },

            ]
        }
    },
    mounted() {
        // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
        // 子页面加载时触发事件
        this.$emit('changeHeight1', '210%');
    },
}
</script>
<style scoped>
.docter-baseinfo {
    margin-top: 10px;
    position: relative;
    display: flex;
    top: 30px;
    width: 100%;
}

.award-box {
    position: relative;
    top: 20px;
}

.project-box {
    position: relative;
    top: 20px;
}

.paper-box {
    position: relative;
    top: 20px;
}


.experience-box {
    position: relative;
    top: 20px;
}

.profile-box {
    position: relative;
    top: 10px;
    width: 80%;
    text-indent: 1em;
    line-height: 25px;
}



.docter-baseinfo-content {
    margin-top: -25px;
    width: 90%;
    margin-left: -15px;

}

.infoss {
    margin-top: 10px;
    font-weight: bold;
    font-size: 20px;
}

ul {
    list-style-type: none;
}

.title-box1 {
    display: flex;
    width: 100%;
}

.docter-baseinfo-infobox {
    position: relative;
    left: 33px;
}
</style>