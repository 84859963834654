<template>
    <div class="PDDD">
        <!-- 网站简介 -->
        <div class="profile-box">
            <!-- 使用 v-html 渲染 profile 的 HTML 内容，即能实现超链接的跳转 -->
            <p v-html="profile1"></p>
            <p v-html="profile2" style="margin-top: -10px;"></p>
            <p v-html="profile3" style="margin-top: -10px;"></p>
            <p v-html="profile4" style="margin-top: -10px;"></p>

        </div>
        <!-- 图片部分  -->
        <div class="image-box">
            <el-image :src="imgurl" fit="cover"></el-image>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: 'PDDD',
            profile1: `PDDD（<a href="https://pd.dd.samlab.cn/" target="_blank">https://pd.dd.samlab.cn/</a>）是一个专注于植物病害诊断领域，为农业生产提供全新的人工智能解决方案。植物病害一直是全球粮食安全的重大威胁，它不仅会减少农作物产量，还会对农业经济造成严重影响。因此，准确高效地诊断植物病害成为了现代农业中的关键环节。传统的病害诊断方法由于耗时长、成本高、效率低且主观性强，逐渐被先进的人工智能技术所取代。
        `,
            profile2: "作为主流的人工智能方法，深度学习在精准农业中的应用已经显著提高了植物病害的检测和诊断能力。然而，目前许多病害诊断方法依赖于计算机视觉领域的预训练模型，这些模型缺乏植物学领域的专业知识，难以准确区分植物病害类型，导致诊断精度受限，并增加了模型训练的复杂性。",
            profile3: "为了应对这些挑战，我们提出了一系列基于植物病害图像的预训练模型。这些模型专为植物病害诊断任务设计，包含植物病害识别、病害检测、病害分割以及其他相关子任务。我们的实验表明，这些植物病害预训练模型在诊断精度和训练效率方面均优于传统的通用预训练模型。它们不仅能够显著提升植物病害诊断的准确性，还能减少训练时间，为用户提供高效可靠的解决方案。",
            profile4: "通过这个平台，我们希望将植物病害诊断的最新研究成果分享给植物学家、农民以及园艺从业者，为农业生产的可持续发展贡献一份力量。无论您是需要专业的诊断工具，还是对植物病害研究充满兴趣，我们都致力于为您提供最先进的技术支持和深入的学术探讨。探索植物健康领域的未来，尽在我们的网站！",
            imgurl: '../../static/image/webs/PDDD.png',
        }
    },
    methods: {
    },
    mounted() {
        // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
        // 子页面加载时触发事件
        this.$emit('changeHeight1', '110%');
    },
}
</script>

<style scoped>
.PDDD {
    width: 90%;
}

.profile-box {
    position: relative;
    top: 20px;
    width: 100%;
}

.profile-box p {
    font-size: 16px;
    
    text-indent: 2em;
    line-height: 25px
}

.image-box {
    margin-top: 50px;
}
</style>