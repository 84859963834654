<template>
    <div class="PRKGE">
        <!-- 网站简介 -->
        <div class="profile-box">
            <!-- 使用 v-html 渲染 profile 的 HTML 内容，即能实现超链接的跳转 -->
            <p v-html="profile1"></p>
            <p v-html="profile2" style="margin-top: -10px;"></p>
            <p v-html="profile3" style="margin-top: -10px;"></p>
            <p v-html="profile4" style="margin-top: -10px;"></p>

        </div>
        <!-- 图片部分  -->
        <div class="image-box">
            <el-image :src="imgurl" fit="cover"></el-image>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: 'PRKGE',
            profile1: `PRKGE（<a href="http://pesticide-repurposing.samlab.cn/" target="_blank">http://pesticide-repurposing.samlab.cn/</a>）是一个专注于通过技术创新为农业发展提供高效解决方案，特别是在农药的再利用与精准化应用方面。
        `,
            profile2: "农药是控制农业害虫和病害、提高作物产量的关键工具。然而，新型农药的开发需要投入大量资源和时间，这导致农药供应面临短缺。同时，传统的农药设计方法过于依赖田间试验和生物测定，缺乏系统性的指导，效率较低。为了解决这些问题，我们首创了一种基于知识图谱嵌入（KGE）和链接预测的农药再利用方法，从药物再利用的研究中汲取灵感，开启了农药设计的新篇章。",
            profile3: "这一方法能够有效推广到未见过的农药与病害关系中，为农药的精准应用和再利用提供了系统性指导。通过科学的预测，减少了对田间试验的依赖，加快了农药开发与应用的进程。此外，这一创新为相关的生化实验提供了坚实的理论基础和实践动机。",
            profile4: "我们还特别构建了一个全面的农药知识数据库，并提供开源的代码和数据资源，供用户下载和使用。无论您是研究农药的专家，还是希望在农业生产中找到更高效的病害控制方案，我们的平台都能为您提供强大的支持。",
            imgurl: '../../static/image/webs/PRKGE.png',
        }
    },
    methods: {
    },
    mounted() {
        // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
        // 子页面加载时触发事件
        this.$emit('changeHeight1', '135%');
    },
}
</script>

<style scoped>
.PRKGE {
    width: 90%;
}

.profile-box {
    position: relative;
    top: 20px;
    width: 100%;
}

.profile-box p {
    font-size: 16px;
    
    text-indent: 2em;
    line-height: 25px
}

.image-box {
    margin-top: 50px;
}
</style>