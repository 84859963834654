<template>
    <div class="MWPS">
        <!-- 网站简介 -->
        <div class="profile-box">
            <!-- 使用 v-html 渲染 profile 的 HTML 内容，即能实现超链接的跳转 -->
            <p v-html="profile1"></p>
            <p v-html="profile2" style="margin-top: -10px;"></p>
            <p v-html="profile3" style="margin-top: -10px;"></p>
            <p v-html="profile4" style="margin-top: -10px;"></p>

        </div>
        <!-- 图片部分  -->
        <div class="image-box">
            <el-image :src="imgurl" fit="cover"></el-image>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: 'MWPS',
            profile1: `MWPS（<a href="https://wirps.samlab.cn/" target="_blank">https://wirps.samlab.cn/</a>）是一个专注于农业领域提供先进的杂草侵染率预测系统，通过创新的RGB与红外图像跨模态融合技术，帮助农业工作者更高效地进行杂草管理与控制。我们利用无人机和多光谱相机，收集辣椒幼苗期田间杂草数据，实现了快速的杂草侵染率预测，为农业生产提供精准支持。
        `,
            profile2: "我们的系统采用了RGB和红外图像跨模态融合的方法，通过结合可见光和红外图像，克服了不同模态数据之间的差异，从而获得更高质量的图像融合结果。这一创新技术能够有效缓解模态差异带来的挑战，使得图像的融合效果更加精准，提升了预测结果的准确性。",
            profile3: "我们收集并建立了一个早期辣椒田杂草的多模态数据库（PWMD），其中包含1495对可见光与红外图像数据。这些数据为我们开发的预测系统提供了坚实的数据支持，同时也为其他相关研究和应用提供了宝贵的资源。",
            profile4: "我们的目标是通过智能化的技术手段，提高农业杂草管理的效率和准确性，帮助农业工作者减少人工成本，提升农作物的产量和质量。随着更多的田间数据不断加入，我们计划继续优化系统，扩大其适用范围，推动农田管理智能化的发展。我们相信，通过结合无人机、红外成像和深度学习技术，未来的农业将变得更加精准、高效。",
            imgurl: '../../static/image/webs/MWPS.png',
        }
    },
    methods: {
    },
    mounted() {
        // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
        // 子页面加载时触发事件
        this.$emit('changeHeight1', '115%');
    },
}
</script>

<style scoped>
.MWPS {
    width: 90%;
}

.profile-box {
    position: relative;
    top: 20px;
    width: 100%;
}

.profile-box p {
    font-size: 16px;
    
    text-indent: 2em;
    line-height: 25px
}

.image-box {
    margin-top: 50px;
    margin-left: 5%;
}
</style>