<template>
    <div class="Doctor">
        <div v-for="i in docters" :key="i">
            <!-- 头像和基本信息 -->
            <div class="docter-baseinfo">
                <div>
                    <el-image style="height: 200px; width: 166px;" :src="i.imgURl"></el-image>
                </div>
                <div class="docter-baseinfo-infobox">
                    <div class="title-box1">
                        <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                            src="../../static/image/icon.png"></el-image>
                        <h2 style="width: 100px; margin-top: 0px;">基本信息</h2>
                    </div>
                    <!-- 基本信息 -->
                    <div class="docter-baseinfo-content">
                        <ul>
                            <li class="infoss">
                                <span>{{ i.nema }}</span>
                            </li>
                            <!-- 个人简介 -->
                            <li class="profile-box">{{ i.profile }}</li>
                        </ul>
                    </div>
                    <!-- 代表论文 -->
                    <div class="paper-box">
                        <div class="experience-infobox">
                            <div class="title-box1">
                                <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                                    src="../../static/image/icon.png"></el-image>
                                <h2 style="width: 150px; margin-top: 0px;">代表论文</h2>
                            </div>
                            <ul style="list-style-type: disc; margin-top: -15px;">
                                <li v-for="item in i.papers" :key="item.id" class="infos">
                                    <span>{{ item.value }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>


        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            imageUrl: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
            docters: [
                {
                    imgURl: '../../static/image/students/graduate/邓宏宇.png',
                    nema: '邓宏宇',
                    profile: '图像描述/多模态融合。中国工商银行软件开发中心，年薪18+',

                    papers: [
                        { value: "CDKM: Common and Distinct Knowledge Mining Network with Content Interaction for Dense Captioning" },
                        { value: "LCM-Captioner: A Lightweight Text-based Image Captioning method with Collaborative Mechanism between Vision and Text" },
                        { value: "Meta-learning shows great potential in plant disease recognition under few available samples" },

                    ]
                },
                {
                    imgURl: '../../static/image/students/graduate/王建军.png',
                    nema: '王建军',
                    profile: '细粒度分类。苏商银行，年薪28+',

                    papers: [
                        { value: "AA-trans: Core attention aggregating transformer with information entropy selector for fine-grained visual classification" },

                    ]
                },


            ]
        }
    }
}
</script>
<style scoped>
.docter-baseinfo {
    margin-top: 10px;
    position: relative;
    display: flex;
    top: 30px;
    width: 100%;
}

.award-box {
    position: relative;
    top: 20px;
}

.project-box {
    position: relative;
    top: 20px;
}

.paper-box {
    position: relative;
    top: 5px;
    width: 80%;
}


.experience-box {
    position: relative;
    top: 20px;
}

.profile-box {
    position: relative;
    top: 10px;
    width: 140%;

}



.docter-baseinfo-content {
    margin-top: -25px;
    width: 90%;
    margin-left: -15px;

}

.infoss {
    margin-top: 5px;
    font-weight: bold;
    font-size: 20px;
}

ul {
    list-style-type: none;
}

.title-box1 {
    display: flex;
    width: 100%;
}

.docter-baseinfo-infobox {
    position: relative;
    left: 33px;
}
</style>