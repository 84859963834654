<template>
    <div class="AutoLIA">
        <!-- 网站简介 -->
        <div class="profile-box">
            <!-- 使用 v-html 渲染 profile 的 HTML 内容，即能实现超链接的跳转 -->
            <p v-html="profile1"></p>
            <p v-html="profile2" style="margin-top: -10px;"></p>
            <p v-html="profile3" style="margin-top: -10px;"></p>
            <p v-html="profile4" style="margin-top: -10px;"></p>

        </div>
        <!-- 图片部分  -->
        <div class="image-box">
            <el-image :src="imgurl" fit="cover"></el-image>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: 'AutoLIA',
            profile1: `AutoLIA（<a href="https://auto.lia.samlab.cn/" target="_blank">https://auto.lia.samlab.cn/</a>）是一个专注于农业和植物研究领域提供高效、非侵入性的植物监测解决方案。
        `,
            profile2: "我们最新推出的Auto-LIA系统，旨在通过计算机视觉技术和RGB图像的结合，实现对植物叶片倾斜角（LIA）的高效自动化测量。LIA是植物生理状态的重要参数之一，不仅能够反映植物的内部特性，还能揭示环境因素对植物生长的影响。因此，准确测量叶片倾斜角对农业生产、作物研究和植物保护具有重要意义。",
            profile3: "Auto-LIA系统的最大特点是非侵入性和全流程自动化。通过先进的图像处理技术和RGB图像，系统能够精准捕捉和分析叶片的空间信息，无需依赖传统的昂贵和复杂的设备，如激光雷达（LiDAR）。这一技术不仅显著降低了设备成本，还提高了测量效率，能够满足大规模植物监测的需求。",
            profile4: "Auto-LIA不仅仅是一个测量工具，更是植物保护和农业管理的智能化助手。随着技术的不断进步，我们将持续优化系统，拓展其在作物保护、水分管理、农药吸收等方面的应用，推动农业领域的智能化发展。我们的目标是为农业工作者、研究人员以及植物保护专家提供一种全新的、低成本、高效的植物监测工具，帮助他们更好地了解和管理植物健康，从而提高农业生产效率和可持续性。",
            imgurl: '../../static/image/webs/AutoLIA.png',
        }
    },
    methods: {
    },
    mounted() {
        // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
        // 子页面加载时触发事件
        this.$emit('changeHeight1', '132%');
    },
}
</script>

<style scoped>
.AutoLIA {
    width: 90%;
}

.profile-box {
    position: relative;
    top: 20px;
    width: 100%;
}

.profile-box p {
    font-size: 16px;
    
    text-indent: 2em;
    line-height: 25px
}

.image-box {
    margin-top: 50px;
    margin-left: 5%;
}
</style>