<template>
    <div class="PDDM">
        <!-- 网站简介 -->
        <div class="profile-box">
            <!-- 使用 v-html 渲染 profile 的 HTML 内容，即能实现超链接的跳转 -->
            <p v-html="profile1"></p>
            <p v-html="profile2" style="margin-top: -10px;"></p>
            <p v-html="profile3" style="margin-top: -10px;"></p>
            <p v-html="profile4" style="margin-top: -10px;"></p>

        </div>
        <!-- 图片部分  -->
        <div class="image-box">
            <el-image :src="imgurl" fit="cover"></el-image>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: 'PDDM',
            profile1: `PDDM（<a href="https://pd.dm.samlab.cn/" target="_blank">https://pd.dm.samlab.cn/</a>）是一个专注于植物病害多模态诊断模型及其相关技术的平台，为农业生产和植物健康管理提供全面支持。植物病害对作物的生长和产量造成严重威胁，早期检测病害并采取有效治疗措施对于农业生产中的管理与决策至关重要。然而，目前大多数植物病害诊断技术仅依赖单一模态的数据（如图像数据）进行模型训练，难以全面掌握植物病害的复杂性，限制了诊断模型的精度和泛化能力。
        `,
            profile2: "为了解决这一问题，我们构建了全球首个大规模植物病害多模态数据库——PDDM数据库。该数据库涵盖了40种植物、116种病害或健康类别、205,007张高质量图片，并为每张图片提供了由植物病害专家撰写的两条详细诊断文字描述。基于此数据库，我们提出了一系列植物病害多模态预训练模型（PDDM-Pretrain），包括视觉预训练模型、文本预训练模型，以及基于Transformer架构的多模态预训练模型。",
            profile3: "这些预训练模型为多模态诊断提供了强大的支持，能够作为视觉和文本的特征提取骨干网络，生成具备充分先验知识的多模态特征，供后续的多模态融合模块使用。这种技术突破为植物病害的精准诊断带来了新的可能性，使得诊断模型在处理复杂病害场景时表现更加出色。",
            profile4: "通过本平台，您可以访问PDDM数据库，下载先进的多模态预训练模型，并了解前沿的研究成果和技术应用。我们致力于为植物学家、农民、农业技术人员及研究人员提供强有力的工具和资源，共同推动农业生产的数字化与智能化。无论您是在探索植物病害诊断的新方向，还是寻求高效的实际应用解决方案，我们的平台都将是您不可或缺的伙伴。加入我们，一起开创农业与科技深度融合的未来！",
            imgurl: '../../static/image/webs/PDDM.png',
        }
    },
    methods: {
    },
    mounted() {
        // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
        // 子页面加载时触发事件
        this.$emit('changeHeight1', '150%');
    },
}
</script>

<style scoped>
.PDDM {
    width: 90%;
}

.profile-box {
    position: relative;
    top: 20px;
    width: 100%;
}

.profile-box p {
    font-size: 16px;
    
    text-indent: 2em;
    line-height: 25px
}

.image-box {
    margin-top: 50px;
}
</style>