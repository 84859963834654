<template>
    <div class="PlanText">
        <!-- 网站简介 -->
        <div class="profile-box">
            <!-- 使用 v-html 渲染 profile 的 HTML 内容，即能实现超链接的跳转 -->
            <p v-html="profile1"></p>
            <p v-html="profile2" style="margin-top: -10px;"></p>
            <p v-html="profile3" style="margin-top: -10px;"></p>
            <p v-html="profile4" style="margin-top: -10px;"></p>

        </div>
        <!-- 图片部分  -->
        <div class="image-box">
            <el-image :src="imgurl" fit="cover"></el-image>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: 'PlanText',
            profile1: `PlanText（<a href="https://plantext.samlab.cn/" target="_blank">https://plantext.samlab.cn/</a>）是一个专注于植物病害诊断提供先进的工具，帮助用户将植物表型与性状描述进行有效对齐。
        `,
            profile2: "通过创新的深度学习技术，我们提出了一种工具，通过逐步屏蔽病害图像的诊断文本，帮助准确地匹配植物表型与病害特征。我们已注释了5,728个病害表型图像，并为这些图像提供了专家诊断文本，此外，还为210,000个病害图像提供了标注文本和性状标签，供模型训练与验证使用。",
            profile3: "我们的工具通过分析植物病害图像与相关性状描述之间的关系，逐步屏蔽诊断文本，确保病害特征能够与植物的表型进行精准对齐。这一功能不仅可以帮助用户准确识别植物病害，还能提供深入的病害描述，助力农学研究和农业生产管理。",
            profile4: "我们的平台不仅仅是一个病害诊断工具，更是一个为农业工作者、研究人员和植物学家提供高效决策支持的智能系统。我们希望通过不断丰富和优化数据资源，结合深度学习的先进技术，推动植物病害检测与管理走向更高效、更精准的未来。加入我们，您不仅能得到准确的病害诊断结果，还能享受到持续优化和创新带来的更强大支持。",
            imgurl: '../../static/image/webs/PlanText.png',
        }
    },
    methods: {
    },
    mounted() {
        // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
        // 子页面加载时触发事件
        this.$emit('changeHeight1', '100%');
    },
}
</script>

<style scoped>
.PlanText {
    width: 90%;
}

.profile-box {
    position: relative;
    top: 20px;
    width: 100%;
}

.profile-box p {
    font-size: 16px;
    
    text-indent: 2em;
    line-height: 25px
}

.image-box {
    margin-top: 50px;
}
</style>