<template>
    <div class="teacher">
        <!-- 头像和基本信息 -->
        <div class="teacher-baseinfo">
            <div>
                <el-image style="height: 200px; width: 166px;" :src="imageUrl"></el-image>
            </div>
            <div class="teacher-baseinfo-infobox">
                <div class="title-box3">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 100px; margin-top: 0px;">基本信息</h2>
                </div>
                <!-- 基本信息 -->
                <div class="teacher-baseinfo-content">
                    <ul>
                        <li v-for="item in basicInfo" :key="item.id" class="infos">
                            <span>{{ item.label }}</span>
                            <span>{{ item.value }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 个人简介 -->
        <div class="profile-box">
            <!-- 使用 v-html 渲染 profile 的 HTML 内容，即能实现超链接的跳转 -->
            <p v-html="profile"></p>
        </div>
        <!-- 学习工作经历 -->
        <div class="experience-box">
            <div class="experience-infobox">
                <div class="title-box3">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">学习工作经历</h2>
                </div>
                <ul>
                    <li v-for="item in experiment" :key="item.id" class="infos">
                        <span>{{ item.label }}</span>
                        <span>{{ item.value }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 代表论文 -->
        <div class="paper-box">
            <div class="experience-infobox">
                <div class="title-box3">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">代表论文</h2>
                </div>
                <ul style="list-style-type: disc;">
                    <li v-for="item in papers" :key="item.id" class="infos">
                        <span>{{ item.value }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 科研项目 -->
        <div class="project-box">
            <div class="experience-infobox">
                <div class="title-box3">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">科研项目</h2>
                </div>
                <ol>
                    <li v-for="item in project" :key="item.id" class="infos">
                        <span>{{ item.value }}</span>
                    </li>
                </ol>
            </div>
        </div>
        <!-- 重要奖项 -->
        <div class="award-box">
            <div class="experience-infobox">
                <div class="title-box3">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">重要奖项</h2>
                </div>
                <ol>
                    <li v-for="item in award" :key="item.id" class="infos">
                        <span>{{ item.value }}</span>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'TeacherHaoGeFei',
    data() {
        return {
            imageUrl: '../../static/image/teacher/HaoGeFei.png',
            // 信息数据
            basicInfo: [
                { label: "单位和职称：", value: " 贵州大学精细化工研究开发中心教授" },
                { label: "办公地址：", value: "贵州大学精细化工研究开发中心" },
                { label: "主要研究方向：", value: "农药合理设计的计算化学生物学,新型植物生长调节剂的合理设计" },
                { label: "电子邮箱：", value: "gfhao@gzu.edu.cn" },
            ],
            experiment: [
                { label: "2018.09 ~ 现在：", value: "贵州大学, 精细化工研究开发中心教授" },
                { label: "2015.07 ~ 2018.08：", value: "华中师范大学, 化学学院教授" },
                { label: "2017.03 ~ 2017.04：", value: "加州大学河滨分校, 植物学与植物科学访问教授" },
                { label: "2013.07 ~ 2015.06：", value: "华中师范大学, 化学学院副教授" },
                { label: "2011.07 ~ 2013.06：", value: "华中师范大学, 化学学院讲师" },
                { label: "2013.02 ~ 2013.11：", value: "香港大学博后" },
                { label: "2005.09 ~ 2011.06：", value: "华中师范大学博士" },

            ],
            papers: [
                { value: "Gao YY, Yang WC, Ashby CR Jr*, Hao GF*. Mapping cryptic binding sites of drug targets to overcome drug resistance. Drug Resist Updat. 2023, 67: 100934." },
                { value: "Huang YQ, Sun P, Chen Y, Liu HX, Hao GF*. Song BA. Bioinformatics toolbox for exploring target mutation-induced drug resistance. Brief Bioinform. 2023, bbad033." },
                { value: "Li JH, Muhammad Aslam M, Gao YY, Lei D, Hao GF*, Zhong W*, Chen MX*, Dini-Andreote F. Microbiome-mediated signal transduction within the plant holobiont. Trends Microblol. 2023." },
                { value: "Mei LC, Hao GF*, Yang GF*. Thermodynamic database supports deciphering protein-nucleic acid interactions. Trends Biotechnol. 2023, 41(2): 140- 143." },
                { value: "Shi XX, Wang ZZ, Sun XL, Wang YL, Liu HX, Wang F, Hao GF*, Yang GF*. Toxicological data bank bridges the gap between environmental risk assessment and green organic chemical design in one health world. Green Chem. 2023." }

            ],
            project: [
                { value: "国家杰出青年科学基金：农药信息学(32125033），2022.1-2026.12" },
                { value: "国家重点研发计划项目：绿色除草剂新品种创制与产业化应用（2021YFD1700100），2021.5-2025.12" },
                { value: "国家自然科学基金地区科学基金：新型脱落酸竞争性拮抗剂的设计与生物活性研究(31960548)，2020.1-2023.12" },
                { value: "国家自然科学基金面上项目：PYLs蛋白家族的抗旱成靶性及分子机制研究，(21772059)2018.1-2021.12" },
                { value: "科技部国家重点研发计划项目子课题：基于基因组的靶标发现及其成药性研究(2017YFD0200501)，2017.7-2020.12" },
                { value: "国家自然科学基金面上项目：以P197突变型AHAS为靶标的反抗性除草剂合理设计（21472060），2015.1-2018.12" },
                { value: "湖北省杰出青年科学基金：以原卟啉原氧化酶为靶标的新型光敏激活剂的合理设计及其抗肿瘤活性研究(2015CFA043)，2015.1-2017.12" },
                { value: "武汉市青年科技晨光计划：以抗性杂草AHAS为靶标的新型除草剂的合理设计(2015070404010178），2015.3-2016.12" },
                { value: "教育部全国优秀博士学位论文专项基金：原卟啉原氧化酶的催化机理及抗除草剂突变体的理性设计(201472)，2014.1-2018.12" },
                { value: "教育部霍英东青年教师基金：植物生长物质在杂交稻制种中的应用研究(142017)，2014.1-2016.12" },
                { value: "国家自然科学基金青年项目：新型脱落酸受体激动剂的合理设计(21202055)，2013.1-2015.12" },
                { value: "中国博士后基金特别资助项目：酰基辅酶A结合蛋白的功能研究(2012T50687)，2013.1-2014.12" },

            ],
            award: [
                { value: "2023年，其他：贵州省2022年度十大科技进展，1/9。" },
                { value: "2022年，科技进步奖：第十七届中国青年科技奖，1/1。" },
                { value: "2022年，贵州省科技进步一等奖：防治稻麦恶性杂草的新型除草剂创制与应用，1/9。" },
                { value: " 2022年，贵州省十大科技进展奖：防治稻麦恶性杂草的新型除草剂创制与应用，1/9。" },
                { value: "2021年，获评贵州省普通本科高校“金师”称号。" },
                { value: "2014年，获“楚天学者计划”楚天学子。" },
                { value: "2013年，全国百篇优秀博士学位论文获得者之一。" },
            ],
            profile: `郝格非教授，贵州大学绿色农药全国重点实验室，博士研究生导师，研究方向为农药信息学。入选国家级高层次人才、作为首席科学家主持国家重点研发项目计划、第十七届中国青年科技奖获得者、全国百篇优秀博士学位论文。他获得者作为团队的首席指导专家，共同开展和指导团队与智慧农业相关课题研究，他的研究方向是农药信息学。详细个人网页如下：<a href="https://gpabl.gzu.edu.cn/2023/0807/c18863a195026/page.htm" target="_blank">https://gpabl.gzu.edu.cn/2023/0807/c18863a195026/page.htm</a>`
        }
    },
    mounted() {
        // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
        // 子页面加载时触发事件
        this.$emit('changeHeight1', '205%');
    },
}
</script>

<style scoped>
.award-box {
    position: relative;
    top: 20px;
}

.project-box {
    position: relative;
    top: 20px;
}

.paper-box {
    position: relative;
    top: 20px;
}


.experience-box {
    position: relative;
    top: 20px;
}

.profile-box {
    position: relative;
    top: 20px;
    width: 100%;
}

.profile-box p {
    text-indent: 2em;
    line-height: 25px
}

.teacher-baseinfo-content {
    width: 100%;
}

.infos {
    margin-top: 10px;
}

ul {
    list-style-type: none;
}

.title-box3 {
    display: flex;
    width: 100%;
}

.teacher-baseinfo-infobox {
    position: relative;
    left: 33px;
}

.teacher-baseinfo {
    position: relative;
    display: flex;
    top: 30px;
    width: 100%;
}

.teacher {
    width: 89%;
}
</style>