<template>
    <div class="searchDirection">
        <el-container>
            <!-- 侧边栏 -->
            <el-aside width="220px" class="aside-box">
                <!-- 标题名和那两个小框框 -->
                <div class="title-box">
                    <div class="boxs">
                        <div class="solid-box1"></div>
                        <div class="solid-box2"></div>
                    </div>
                    <div class="aside-title">
                        <p style="font-size: 26px; margin-top: 30px;width: 150px;">研究方向</p>
                    </div>
                </div>
                <!-- 左边的导航栏 -->
                <div class="main-navbar">
                    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="vertical" @select="handleSelect"
                        active-text-color="#FB9220" router>
                        <el-menu-item v-for="item in researchDirections" :key="item.path" :index="item.path">

                            <template v-slot:title>
                                <span v-if="activeIndex === item.path">▶ {{ item.name }}</span>
                                <span v-else>{{ item.name }}</span>
                            </template>
                        </el-menu-item>
                    </el-menu>
                </div>
            </el-aside>
            <!-- 右边的主要内容 -->
            <el-main class="main-content">
                <router-view @changeHeight1="changeHeight1"></router-view>
            </el-main>
        </el-container>

    </div>
</template>

<script>
export default {
    data() {
        return {
            defaultIndex: '/Direction1',
            activeIndex: '/Direction1',
            researchDirections: [
                {
                    name: '大数据与人工智能基础研究',
                    path: '/Direction1',
                    content: '大数据与人工智能基础研究'
                },
                {
                    name: '植物智慧医学',
                    path: '/Direction2',
                    content: '大数据与人工智能应用研究'
                },
                {
                    name: '人工智能分子设计',
                    path: '/Direction3',
                    content: '大数据与人工智能教育研究'
                },
                {
                    name: '农业大数据智能应用',
                    path: '/Direction4',
                    content: '大数据与人工智能产业研究'
                }
            ]
        };
    },
    methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
            this.activeIndex = key;
        },
        changeHeight1(newHeight) {
            document.documentElement.style.setProperty('--main-block-height', newHeight);
        },
    },
    mounted() {
        this.$router.push(this.defaultIndex); // 页面加载时导航到默认路由
    }

}
</script>

<style scoped>
.searchDirection {
    /* width: 100%; */
    display: flex;
    height: 100vh;

}

.title-box {
    height: 107px;
    background-color: white;
    width: 220px;

}

.aside-box {
    background-color: #F1F4F7;
    height: var(--main-block-height, 100%);
    color: #333;
    text-align: center;
    overflow: hidden;

}

.aside-title {
    background-color: white;
    width: 150px;
    text-align: left;
    position: relative;
    left: 36px;
}

.boxs {
    display: flex;
    position: relative;
    top: 30px;
    left: 36px;
}

.solid-box1 {
    width: 25px;
    height: 25px;
    background-color: #6D976B;


}

.solid-box2 {
    width: 25px;
    height: 25px;
    background-color: #FB9220;
    /* opacity: 0.21; */
    position: relative;
    left: 4px;
}

.main-navbar {
    width: 220px;
    height: 100vh;
    background-color: #F1F4F7;
}

.el-menu-demo {
    text-align: left;
    background-color: #F1F4F7;
    position: relative;
}

.el-menu-item {
    height: 50px;
    width: 220px;
    padding-left: 35px;
    font-size: 15px;
}

.el-menu-item.is-active {
    background-color: #dfecf8 !important;
}

.main-content {
    position: relative;
    left: 4%;
    overflow: visible;
    /* 禁止自身滚动条 */

}
</style>