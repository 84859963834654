<template>
  <div class="teacher">
      <!-- 头像和基本信息 -->
      <div class="teacher-baseinfo">
          <div>
              <el-image style="height: 200px; width: 166px;" :src="imageUrl"></el-image>
          </div>
          <div class="teacher-baseinfo-infobox">
              <div class="title-box3">
                  <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                      src="../../static/image/icon.png"></el-image>
                  <h2 style="width: 100px; margin-top: 0px;">基本信息</h2>
              </div>
              <!-- 基本信息 -->
              <div class="teacher-baseinfo-content">
                  <ul>
                      <li v-for="item in basicInfo" :key="item.id" class="infos">
                          <span>{{ item.label }}</span>
                          <span>{{ item.value }}</span>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
      <!-- 个人简介 -->
      <div class="profile-box">
          <p>{{ profile }}</p>
          <p>{{ profile2 }}</p>
      </div>
      <!-- 学习工作经历 -->
      <div class="experience-box">
          <div class="experience-infobox">
              <div class="title-box3">
                  <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                      src="../../static/image/icon.png"></el-image>
                  <h2 style="width: 150px; margin-top: 0px;">学习工作经历</h2>
              </div>
              <ul>
                  <li v-for="item in experiment" :key="item.id" class="infos">
                      <span>{{ item.label }}</span>
                      <span>{{ item.value }}</span>
                  </li>
              </ul>
          </div>
      </div>
      <!-- 代表论文 -->
      <div class="paper-box">
          <div class="experience-infobox">
              <div class="title-box3">
                  <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                      src="../../static/image/icon.png"></el-image>
                  <h2 style="width: 150px; margin-top: 0px;">代表论文</h2>
              </div>
              <ul style="list-style-type: disc;">
                  <li v-for="item in papers" :key="item.id" class="infos">
                      <span>{{ item.value }}</span>
                  </li>
              </ul>
          </div>
      </div>
      <!-- 科研项目 -->
      <div class="project-box">
          <div class="experience-infobox">
              <div class="title-box3">
                  <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                      src="../../static/image/icon.png"></el-image>
                  <h2 style="width: 150px; margin-top: 0px;">科研项目</h2>
              </div>
              <ol >
                  <li v-for="item in project" :key="item.id" class="infos">
                      <span>{{ item.value }}</span>
                  </li>
              </ol>
          </div>
      </div>
      <!-- 重要奖项 -->
      <div class="award-box">
          <div class="experience-infobox">
              <div class="title-box3">
                  <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                      src="../../static/image/icon.png"></el-image>
                  <h2 style="width: 150px; margin-top: 0px;">重要奖项</h2>
              </div>
              <ol >
                  <li v-for="item in project" :key="item.id" class="infos">
                      <span>{{ item.value }}</span>
                  </li>
              </ol>
          </div>
      </div>
  </div>
</template>

<script>

export default {
  name: 'TeacherWangQi',
  data() {
      return {
          imageUrl: '../../static/image/teacher/LiYuQuan.png',
          // 信息数据
          basicInfo: [
              { label: "单位和职称：", value: "贵州大学公共大数据国家重点实验室特聘教授" },
              { label: "办公地址：", value: "贵州省贵阳市花溪区贵州大学西校区崇厚楼" },
              { label: "主要研究方向：", value: "AI基础研究(大语言模型、图学习)，AI分子设计应用研究(分子生成及多目标优化、分子性质预测及筛选、多组学数据挖掘及靶标发现)/高光谱方向" },
              { label: "电子邮箱：", value: "yvquan.li@gmail.com" },
          ],
          // 学习工作经历
          experiment: [
              { label: "2024.10 ~ 现在：", value: "贵州大学公共大数据国家重点实验室特聘教授,硕导" },
              { label: "2022.07 ~ 2023.04：", value: "北京智源人工智能研究院付杰博士团队研究实习访学" },
              { label: "2020.08 ~ 2022.06：", value: "腾讯公司量子实验室联合培养(合作导师：谢昌谕博士)" },
              { label: "2019.09 ~ 2024.06：", value: "硕博 - 兰州大学，化学化工学院(专业：化学信息学，导师：姚小军教授)" },
              { label: "2015.09 ~ 2019.06：", value: "本科 - 青海大学，计算机学院(专业：计算机科学与技术)" },
          ],
          // 代表论文
          papers: [
              { value: "Yuquan Li, et al. An adaptive graph learning method for automated molecular interactions and properties predictions. Nature Machine Intelligence. 2022. (第一作者，Nature子刊，当年IF=23.8，中科院1区)" },
              { value: "Yuquan Li, et al. Introducing block design in graph neural networks for molecular properties prediction[J]. Chemical Engineering Journal. 2021. (第一作者，当年IF=16.7，中科院1区)" },
              { value: "Xiaorui Wang†, Yuquan Li†, et al. RetroPrime: A Diverse, plausible and Transformer-based method for Single-Step retrosynthesis predictions. Chemical Engineering Journal. 2021. (共同第一作者，当年IF=15.1，中科院1区)" },
              { value: "Pengyong Li†, Yuquan Li†, et al. TrimNet : learning molecular representation from triplet messages for biomedicine[J]. Briefings in Bioinformatics. 2021. (共同第一作者，当年IF=13.9，中科院2区)" },
            

          ],
          // 项目
          project: [
              { value:"贵州大学人才引进特岗项目，基于人工智能的多约束小分子化合物设计平台研究, 2024.10-2028.10，40万，主持。"},
          ],
          // 获奖
          award:[
              { value:"2024中国国际大数据博览会十大科技领先成果奖"},
              { value:"第二届全国博士后创新创业大赛，1项国家级银奖"},
              { value:"第十八届“挑战杯”全国大学生课外学术科技作品竞赛，1项国家级特等奖"},
              { value:"第十四届蓝桥杯全国软件和信息技术专业人才大赛， 2023.06，1项省赛一等奖，1项国家级三等奖"},
              { value:"2022年大学生创新创业训练计划项目，2022.11，国家级立项"},
              { value:"2023中国大学生工程实践与创新能力大赛省赛，2023.11，贵州省特等奖及国家级银奖"},
              { value:"2023“华为杯”中国研究生数学建模竞赛，2023.11，2项国家级三等奖"},
              { value:"第十八届“挑战杯”全国大学生课外学术科技作品竞赛，2023.03，2项省赛一等奖"},
          ],
          // 个人简介
          profile: "黎育权，特聘教授，硕士生导师，目前就职于贵州大学公共大数据国家重点实验室，兰州大学硕博连读提前毕业博士，在读期间曾前往腾讯公司量子实验室进行两年期联合培养，合作导师为谢昌谕博士。曾前往北京智源人工智能实验室付杰研究员团队进行研究实习访问。",
          profile2:'长期从事AI基础研究和AI分子设计应用方向的研究工作，相关成果以第一/通讯作者身份在Nature Machine Intelligence、Chemical Engineering Journal、Briefings in Bioinformatics在内的多个期刊发表。截至目前，共在Nature Machine Intelligence, Nature Communication等期刊共发表SCI论文10篇，其中一作或通讯身份4篇，篇均引用57次。担任Exploration期刊青年编委。'
      }
  }
}
</script>

<style scoped>
.award-box{
  position: relative;
  top: 20px;
}
.project-box {
  position: relative;
  top: 20px;
}

.paper-box {
  position: relative;
  top: 20px;
}


.experience-box {
  position: relative;
  top: 20px;
}

.profile-box {
  position: relative;
  top: 20px;
  width: 100%;
}

.profile-box p {
  text-indent: 2em;
  line-height: 25px
}

.teacher-baseinfo-content {
  width: 100%;
}

.infos {
  margin-top: 10px;
}

ul {
  list-style-type: none;
}

.title-box3 {
  display: flex;
  width: 100%;
}

.teacher-baseinfo-infobox {
  position: relative;
  left: 33px;
}

.teacher-baseinfo {
  position: relative;
  display: flex;
  top: 30px;
  width: 100%;
}

.teacher {
  width: 89%;
}
</style>