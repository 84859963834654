<template>
    <div class="direction1">
        <div style="color: #BCBCBC; font-size: 40px;position: relative;top: 20px;">{{ TileName }}</div>
        <div class="line"></div>
        <!-- 标题块  -->
        <div class="nameBox">
            <div class="numBox">
                <!-- <h3>1</h3> -->
                <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                    src="../../static/image/iconss/SmartNong.png"></el-image>
            </div>
            <div class="titleBox">
                <h3 style="position: relative;left: 23px;">农业大数据智能应用</h3>
            </div>
        </div>
        <!-- 内容块 -->
        <div>
            <div class="contentBox">
                <p>团队在农业大数据智能应用方面，以多光谱和高光谱感知技术为核心，通过整合田间作物的多源数据，构建面向农业生产的智能化解决方案。研究内容包括农业田间病害监测与防控、多模态数据驱动的瓜果类作物3D感知技术、精准的田间作物计数与产量预测模型开发，以及基于全生命周期数据的作物生长监测与管理系统。通过人工智能、大数据分析和视觉感知技术的深度融合，研究旨在实现作物健康与产量的精细化管理，为智慧农业提供科学决策支持，助力现代农业的高效与可持续发展。-感知算法-用药决策”三方面着手，构建作物病害多模态智能诊断技术解决上述问题。
                </p>

                <p>团队研究内容：多光谱和高光谱感知、农业田间病害监测与应用、田间瓜果类作物的3D感知、田间作物计数和产量预估、作物全生命周期的生长监测。
                </p>

            </div>

            <!-- 代表性结果块 -->
            <div class="Representative-Box">

                <div class="title-box3">
                    <el-image style="height: 20px; width: 20px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h3 style="width: 300px; margin-top: 0px;">实验室代表性成果：</h3>
                </div>

                <ol>
                    <li v-for="item in representativeResults" :key="item.id">{{ item.value }}</li>
                </ol>
            </div>

            <el-image class="imageBox" style="width: 100%;" src="../../static/image/Direction4.1.png" fit="fits" />
        </div>


    </div>

</template>



<script>
export default {
    data() {
        return {
            TileName: 'Research Direction',
            researchItems: [
                { id: 1, title: "植物智慧医学" }
            ],
            representativeResults: [
                { value: "Sijun Jiang, Xingcai Wu, Qi Wang*, Zhixun Pei, Yuxiang Wang, Jian Jin, Ying Guo, Runjiang Song, Liansheng Zang, Yong-Jin Liu. Auto-LIA: The Automated Vision-based Leaf Inclination Angle Measurement System Improves Monitoring of Plant Physiology. Plant Phenomics, 2024. (IF: 7.6, SCI 1, Top)" },
                { value: "Dongyu Chen, Yingwei Liu, Yang Liu, Kejun Zhao, Tianhan Zhang, Yangyang Gao, Qi Wang, Baoan Song, Gefei Hao, ChemFREE: a one-stop comprehensive platform for ecological and environmental risk evaluation of chemicals in one health world, Nucleic Acids Research, 2024;, gkae446. (IF: 14.9, SCI 2, Top)" },
                { value: "Yaoxi Li, Xingcai Wu, Qi Wang*, Zhixun Pei, Kejun Zhao, Panfeng Chen, Gefei Hao. CSNet: A Count-supervised Network via Multiscale MLP-Mixer for Wheat Ear Counting. Plant Phenomics, 2024. (IF: 7.6, SCI 1, Top)" },
                { value: "Ting Wen, Jian-Hong Li, Qi Wang*, Yang-Yang Gao, Ge-Fei Hao, and Bao-An Song. Thermal imaging: The digital eye facilitates high-throughput phenotyping traits of plant growth and stress responses. Science of The Total Environment, 2023, 899:165626. (IF: 9.8, SCI 1, Top)" },
            ]
        }
    },
    mounted() {
        // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
        // 子页面加载时触发事件
        this.$emit('changeHeight1', '145%');
    },
}
</script>
<style scoped>
.title-box3 {
    display: flex;
    width: 100%;
    margin-bottom: -30px;
    margin-top: 10px;
}

.Representative-Box {
    position: relative;
    top: 60px;
    width: 95%;
    /* left: 1%; */

}

.Representative-Box ol {
    list-style-type: decimal;
    /* padding: 0px;
    margin: 0px; */
}

.Representative-Box ol li {
    line-height: 24px;
}

.imageBox {
    position: relative;
    top: 50px;

}

.contentBox {
    position: relative;
    top: 60px;

}

.contentBox p {
    font-weight: 500;
    text-align: left;
    text-indent: 2em;
    padding: 0px;
    margin: 0px;
    line-height: 30px;
}

.nameBox {
    display: flex;
    position: relative;
    top: 49px;

}

.direction1 {
    width: 90%;
}

.line {
    position: relative;
    top: 30px;
    bottom: 32.5px;
    border: 0.1px solid #BCBCBC;
    width: 100%;
}

.numBox {
    width: 67px;
    height: 67px;
    background-color: #D4DFEB;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #2448FF;

}

.titleBox {
    height: 67px;
    width: 100%;
    font-size: 17px;
    color: #2E5A2D;
    background-color: #F7F9FA;
    justify-content: center;
    align-items: center;

}
</style>