<template>
    <div class="TPDNet">
        <!-- 网站简介 -->
        <div class="profile-box">
            <!-- 使用 v-html 渲染 profile 的 HTML 内容，即能实现超链接的跳转 -->
            <p v-html="profile1"></p>
            <p v-html="profile2" style="margin-top: -10px;"></p>
            <p v-html="profile3" style="margin-top: -10px;"></p>

        </div>
        <!-- 图片部分  -->
        <div class="image-box">
            <el-image :src="imgurl" fit="cover"></el-image>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: 'TPDNet',
            profile1: `TPDNet（<a href="https://tpdnet.samlab.cn/" target="_blank">https://tpdnet.samlab.cn/</a>）是一个专注于推动农业自动化技术的发展，为水果和蔬菜的高效采摘提供智能化解决方案。随着全球人口的增长，对水果和蔬菜的需求不断增加，而高昂的采摘劳动成本（占总成本的30%至50%）严重制约了农业行业的发展。
        `,
            profile2: "传统的2D目标检测技术虽然在一定程度上实现了自动化采摘，但仅能提供平面信息，无法满足需要3D空间数据的应用场景。而3D目标检测技术则可以有效解决这一问题，其应用包括点云检测方法和单目检测方法。然而，点云检测需要昂贵的设备，难以适用于低成本农业采摘场景；相较之下，单目3D目标检测方法仅需摄像头即可运行，部署便捷，适合农业领域的广泛应用。",
            profile3: "通过我们的网站，用户可以深入了解TPDNet的技术细节，下载相关数据集与模型，并获取更多关于农业自动化与智能采摘的前沿研究资讯。我们致力于为农业技术人员、研究学者和行业从业者提供高效实用的工具和资源，共同推动农业生产的智能化与现代化。加入我们，一起探索农业科技的未来，为全球农业可持续发展贡献力量！",
            imgurl: '../../static/image/webs/TPDNet.png',
        }
    },
    methods: {
    },
    mounted() {
        // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
        // 子页面加载时触发事件
        this.$emit('changeHeight1', '130%');
    },
}
</script>

<style scoped>
.TPDNet {
    width: 85%;
}

.profile-box {
    position: relative;
    top: 20px;
    width: 100%;
}

.profile-box p {
    font-size: 16px;
    
    text-indent: 2em;
    line-height: 25px
}

.image-box {
    margin-top: 50px;
}
</style>