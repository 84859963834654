<template>
    <div class="HLNet">
        <!-- 网站简介 -->
        <div class="profile-box">
            <!-- 使用 v-html 渲染 profile 的 HTML 内容，即能实现超链接的跳转 -->
            <p v-html="profile1"></p>
            <p v-html="profile2" style="margin-top: -10px;"></p>
            <p v-html="profile3" style="margin-top: -10px;"></p>
            <p v-html="profile4" style="margin-top: -10px;"></p>

        </div>
        <!-- 图片部分  -->
        <div class="image-box">
            <el-image :src="imgurl" fit="cover"></el-image>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: 'HLNet',
            profile1: `HLNet（<a href="https://hlnet.samlab.cn/" target="_blank">https://hlnet.samlab.cn/</a>）是一个专注于推动遥感图像领域的技术创新，特别是在高空间分辨率和高光谱分辨率图像的超分辨率重建方面。
        `,
            profile2: "遥感监测中的图像通常需要同时具备高空间分辨率和高光谱分辨率，以提供更精准的环境和资源信息。然而，由于硬件设备的限制，获取高分辨率的高光谱图像（HR-HSI）仍然是一个巨大的挑战。传统的光谱重建方法和超分割方法多是串联使用，忽略了空间与光谱特征之间的关联，这限制了高分辨率高光谱图像的有效生成和应用。",
            profile3: "为了突破这一瓶颈，我们提出了一种全新的混合学习网络（HLNet），旨在通过同时学习空间和光谱特征之间的关联，来实现空间-光谱超分辨率（SSSR）。我们的技术创新体现在以下几个方面：(1)密集特征提取器（DFE），(2)混合特征学习模块（HLM），(3)混合特征重建模块（HRM），(4)间隔损失（Interval Loss）",
            profile4: "通过我们的网站，用户可以深入了解HLNet的技术原理，下载相关的高光谱数据集与模型，并获取更多关于遥感图像分析的前沿研究资讯。我们致力于为遥感研究人员、环境监测专家以及各类数据分析人员提供强大而易用的工具和技术支持。我们的目标是推动遥感图像处理技术的发展，特别是在农业监测、环境保护和资源管理等领域的应用，助力全球的智能化决策和可持续发展。加入我们，一起探索遥感技术的未来，提升全球监测与管理的精准度！",
            imgurl: '../../static/image/webs/HLNet.png',
        }
    },
    methods: {
    },
    mounted() {
        // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
        // 子页面加载时触发事件
        this.$emit('changeHeight1', '120%');
    },
}
</script>

<style scoped>
.HLNet {
    width: 90%;
}

.profile-box {
    position: relative;
    top: 20px;
    width: 100%;
}

.profile-box p {
    font-size: 16px;
    
    text-indent: 2em;
    line-height: 25px
}

.image-box {
    margin-top: 50px;
}
</style>