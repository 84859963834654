<template>
  <div class="SAMLABWeb">
    <!-- 网站简介 -->
    <div class="profile-box">
      <!-- 使用 v-html 渲染 profile 的 HTML 内容，即能实现超链接的跳转 -->
      <p v-html="profile1"></p>
      <p v-html="profile2" style="margin-top: -10px;"></p>
      <p v-html="profile3" style="margin-top: -10px;"></p>
    </div>
    <!-- 图片部分  -->
    <div class="image-box">
      <el-image :src="imgurl" fit="cover" ></el-image>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: 'SAMLAB Web',
      profile1: `GZU-SAMLab（<a href="https://github.com/GZU-SAMLab" target="_blank">https://github.com/GZU-SAMLab</a>）是智慧农业与多媒体实验室的官方GitHub网站，该实验室由王崎老师创建，隶属于贵州大学公共大数据国家重点实验室。`,
      profile2: "我们实验室的GitHub是一个开放、协作的技术共享平台，致力于推动农业与人工智能技术的深度融合，专注于机器学习、计算机视觉和自然语言处理等领域的交叉研究。在这里，我们集中展示了实验室在智能农业场景下的最新研究成果，包括但不限于农作物病虫害检测与诊断、农业数据分析与预测、农田遥感图像处理和农业知识图谱构建等方向的核心项目。",
      profile3: "我们的代码库包含丰富的算法实现、数据处理工具以及智能农业解决方案的完整示例，为农业技术从业者、人工智能研究者以及开发者提供了便捷的技术资源。我们注重代码的高质量和易用性，每个项目都配备详细的使用说明和完善的文档，确保用户可以轻松理解和应用我们的技术。此外，我们还积极与开源社区合作，欢迎有兴趣的研究人员和开发者关注我们的项目，共同贡献代码、探讨技术难题或发表见解。通过 GitHub 平台，我们希望促进农业与人工智能技术的创新，推动全球农业智能化的进一步发展！",
      imgurl:'../../static/image/LabIcon.png',
    }
  },
  methods: {
  },
  mounted() {
    // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
    // 子页面加载时触发事件
    this.$emit('changeHeight1', '100%');
  },
}
</script>

<style scoped>
.SAMLABWeb {
  width: 90%;
}

.profile-box {
  position: relative;
  top: 20px;
  width: 100%;
}

.profile-box p {
  font-size: 16px;

  text-indent: 2em;
  line-height: 25px
}
.image-box{
  margin-top: 50px;
  margin-left: 20%;
}
</style>