<template>
    <div style="width: 90%;">
        <!-- 2024年的论文块 -->
        <div>
            <div class="title-box3">
                <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                    src="../../static/image/icon.png"></el-image>
                <h2 style="width: 150px; margin-top: 0px;">2024年</h2>
            </div>
            <ol style="list-style-type: none;">
                <li v-for="(item, index) in papers2024" :key="item.id" class="infos">
                    <span class="custom-counter">{{ reverseIndex(index, 'papers2024') + '. '}}</span>
                    <span>{{ item.value }}</span>
                </li>
            </ol>
        </div>
        <!-- 2023年的论文块 -->
        <div>
            <div class="title-box3">
                <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                    src="../../static/image/icon.png"></el-image>
                <h2 style="width: 150px; margin-top: 0px;">2023年</h2>
            </div>
            <ol style="list-style-type: none;">
                <li v-for="(item, index) in papers2023" :key="item.id" class="infos">
                    <span class="custom-counter">{{ reverseIndex(index, 'papers2023') + '. '}}</span>
                    <span>{{ item.value }}</span>
                </li>
            </ol>
        </div>
        <!-- 2022年的论文块 -->
        <div>
            <div class="title-box3">
                <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                    src="../../static/image/icon.png"></el-image>
                <h2 style="width: 150px; margin-top: 0px;">2022年</h2>
            </div>
            <ol style="list-style-type: none;">
                <li v-for="(item, index) in papers2022" :key="item.id" class="infos">
                    <span class="custom-counter">{{ reverseIndex(index, 'papers2022')+ '. '}}</span>
                    <span>{{ item.value }}</span>
                </li>
            </ol>
        </div>
        <!-- 2021年及以前的论文块 -->
        <div>
            <div class="title-box3">
                <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                    src="../../static/image/icon.png"></el-image>
                <h2 style="width: 200px; margin-top: 0px;">2021年及以前</h2>
            </div>
            <ol style="list-style-type: none;">
                <li v-for="(item, index) in papersBefore2021" :key="item.id" class="infos">
                    <span class="custom-counter">{{ reverseIndex(index, 'papersBefore2021') + '. '}}</span>
                    <span>{{ item.value }}</span>
                </li>
            </ol>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Papers',
    data() {
        return {
            papers2024: [
                {
                    value: 'Guojun Chen, Panfeng Chen, Qi Wang*, Hui Li, Xin Zhou, Xibin Wang, Aihua Yu,Xingzhi Deng. EMGE: Entities and Mentions Gradual Enhancement with semantics and connection modelling for document-level relation extraction, Knowledge-Based Systems,2024. (通讯作者，IF:7.2, SCI 1, Top)',
                },
                {
                    value: 'Kejun Zhao, Xingcai Wu, Yuanyuan Xiao, Sijun Jiang, Peijia Yu, Yazhou Wang, Qi Wang*. PlanText: Gradually Masked Guidance to Align Image Phenotype with Trait Description for Plant Disease Texts. Plant Phenomics, 2024. (通讯作者, IF: 7.6, SCI 1, Top)',
                },
                {
                    value: 'Qinglei Li, Qi Wang*, Yongbin Qin, Xinyu Dong, Xingcai Wu, Shiming Chen, Wu Liu, Member, Yong-Jin Liu, Jiebo Luo. DRC: Discrete Representation Classifier with Salient Features via Fixed-prototype. IEEE Transactions on Circuits and Systems for Video Technology, 2024. (通讯作者, IF: 8.4, CCF B, SCI 1, Top)',
                },
                { value: 'Sijun Jiang, Xingcai Wu, Qi Wang*, Zhixun Pei, Yuxiang Wang, Jian Jin, Ying Guo, Runjiang Song, Liansheng Zang, Yong-Jin Liu. Auto-LIA: The Automated Vision-based Leaf Inclination Angle Measurement System Improves Monitoring of Plant Physiology. Plant Phenomics, 2024. (通讯作者, IF: 7.6, SCI 1, Top)' },
                { value: 'Yaoxi Li, Xingcai Wu, Qi Wang*, Zhixun Pei, Kejun Zhao, Panfeng Chen, Gefei Hao. CSNet: A Count-supervised Network via Multiscale MLP-Mixer for Wheat Ear Counting. Plant Phenomics, 2024. (通讯作者, IF: 7.6, SCI 1, Top)' },
                { value: 'Hongyu Deng, Yushan Xie, Qi Wang*, Jianjun Wang, Weijian Ruan, Wu Liu, YongJin Liu. CDKM: Common and Distinct Knowledge Mining Network with Content Interaction for Dense Captioning. IEEE Transactions on Multimedia, 2024. (通讯作者, IF: 7.3, CCF B, SCI 1, Top)' },
                { value: 'Han Chen, Qi Wang∗, Kailin Xie, Liang Lei, Xue Wu. MPF-Net: Multi-Projection Filtering Network for Few-Shot Object Detection. Applied Intelligence, 2024. (通讯作者, IF: 5.3, CCF C, SCI 2)' },
                { value: 'Jinxiang Zhu#, Qi Wang#*, et al. FSNA: Few-Shot Object Detection via Neighborhood Information Adaption and All Attention. IEEE Transactions on Circuits and Systems for Video Technology, 2024. (共一和通讯, IF: 8.4, CCF B, SCI 1, Top)' },
                { value: 'He Yuze, Bai Yushi, Lin Matthieu, Sheng Jenny, Hu Yubin, Wang Qi*, Wen Yu-Hui, Yong-Jin Liu. Text-Image Conditioned Diffusion for Consistent Text-to-3D Generation. CAGD, 2024:102292. (通讯作者, 指导清华博士生发表CCF B类期刊)' },

                { value: 'Dong, Xinyu, et al. PlantPAD: a platform for large-scale image phenomics analysis of disease in plant science.Nucleic Acids Research 52.D1 (2024): D1556-D1568.' },
                { value: 'Zhao, Fuyong and Li, Yuyang and Wang, Yanhao and Li, Hui and Chen, Mei and Chen, Panfeng and Sun,, et al. Pulsar Candidate Classification with Multimodal Large Language Models. Neurips 2024 Workshop Foundation Models for Science: Progress, Opportunities, and Challenges.' },
                { value: 'Guojun Chen, Panfeng Chen, Hui Li, Xibin Wang, Xin Zhou, Aihua Yu, Xingzhi Deng, Qi Wang. Relation Semantic Guidance and Entity Position Location for Relation Extraction. Data Science and Engineering, 2024 (合作作者, IF: 5.1, SCI 2)' },
                { value: 'Dongyu Chen, Yingwei Liu, Yang Liu, Kejun Zhao, Tianhan Zhang, Yangyang Gao, Qi Wang, Baoan Song, Gefei Hao, ChemFREE: a one-stop comprehensive platform for ecological and environmental risk evaluation of chemicals in one health world, Nucleic Acids Research, 2024;, gkae446. (合作作者, IF: 14.9, SCI 2, Top)' },
                { value: 'Ruoyu Fan, Wang Zhao, Matthieu Lin, Qi Wang, Yong-Jin Liu, and Wenping Wang. Generalizable Thermal-based Depth Estimation via Pre-trained Visual Foundation Model. ICRA, May 13-17 2024. (合作作者, 机器人Top 会议)' },


            ],
            papers2023: [
                { value: 'Han Chen, Qi Wang*, Kailin Xie, Liang Lei, Matthieu Gaetan Lin, Tian lv, Yongjin Liu, Jiebo Luo. SD-FSOD: Self-Distillation Paradigm via Distribution Calibration for Few-Shot Object Detection. IEEE Transactions on Circuits and Systems for Video Technology, 2023.(通讯作者, IF: 8.4, CCF B, SCI 1, Top)' },
                { value: 'Xue Wu, Daohong Gong, Kejun Zhao, Dongyu Chen, Yangyang Gao, Qi Wang*, Yawen Dong Gefei Hao. Global research and development trends in plant growth regulators. Advanced Agrochem, 2023.11. (通讯作者)' },
                { value: 'Yanming Miao, Xianghong Tang, Qi Wang*, Liya Yu. GDENet: Graph Differential Equations Network for Traffic Flow Prediction. International Journal of Intelligent Systems, 2023, 7099652:16. (通讯作者, IF: 7.0, SCI 2, Top)' },
                { value: 'Xinyu Dong#, Kejun Zhao#, Qi Wang#*, Xingcai Wu#, Yuanqin Huang#, et al. PlantPAD: a platform for large-scale image phenomics analysis of disease in plant science. Nucleic Acids Research. 2023, gkad917. (共一和通讯, IF: 14.9, SCI 2, Top)' },
                { value: 'Xingcai Wu, Kejun Zhao, Qianding Huang, Qi Wang*, Zhenguo Yang, Gefei Hao. MISL: Multi-grained Image-text Semantic Learning for Text-guided Image Inpainting. Pattern Recognition, 2024,145:109961. (通讯作者, IF: 8.0, SCI 1, CCF B, Top)' },
                { value: 'Ting Wen, Jian-Hong Li, Qi Wang*, Yang-Yang Gao, Ge-Fei Hao, and Bao-An Song. Thermal imaging: The digital eye facilitates high-throughput phenotyping traits of plant growth and stress responses. Science of The Total Environment, 2023, 899:165626. (通讯作者, IF: 9.8, SCI 1, Top)' },
                { value: 'Qianding Huang, Xingcai Wu, Qi Wang*, Xinyu Dong, Liang Lei, Xue Wu, Yangyang Gao, and Gefei Hao. Knowledge Distillation Facilitates the Lightweight and Efficient of Plant Diseases Detection Model. Plant Phenomics, 2023, 5:0062. (通讯作者, IF: 6.961, SCI 1, Top)' },
                { value: 'Xinyu Dong, Qi Wang*, Qianding Huang, Qinglong Ge, Kejun Zhao, Xingcai Wu, Xue Wu, Liang Lei, and Gefei Hao. PDDD-PreTrain: A series of common-used pre-trained models support image-based plant disease diagnosis. Plant Phenomics, 2023, 5:0054. (通讯作者, IF: 6.961, SCI 1, Top)' },
                { value: 'Haolin Chen, Qi Wang*, Weijian Ruan, Jingxiang Zhu, Liang Lei, Xue Wu, Gefei Hao. ALFPN: Adaptive Learning Feature Pyramid Network for Small Object Detection. International Journal of Intelligent Systems, 2023, 04:6266209. (通讯作者, IF: 8.993, SCI 2, Top)' },
                { value: 'Xue Wu, Hongyu Deng, Qi Wang*, YangYang Gao, Liang Lei, Ge-Fei Hao. Meta-learning Shows Great Potential in Plant Diseases Recognition Under Few Available Samples. Plant Journal. 2023, 03. (通讯作者, IF: 7.091, SCI 1, Top)' },
                { value: 'Qi Wang, JianJun Wang, Hongyu Deng, Xue Wu, Yazhou Wang, Gefei Hao. AA-Trans: Core Attention Aggregating Transformer with Information Entropy Selector for Fine-grained Visual Classification. Pattern Recognition, 2023, 140:109547. (第一作者, IF: 8.518, SCI 1, CCF B, Top))' },
                { value: 'Huizhou Zhou, Haoliang Zhao, Qi Wang*, Gefei Hao, Liang Lei. Miper-MVS: Multi-scale Itearative Probability Estimation with Refinement for Efficient Multi-View Stereo. Neural Networks, 2023, 164: 351-362. (通讯作者, IF: 9.657, SCI 1, CCF B, Top)' },
                { value: 'Qi Wang, Hongyu Deng, Xue Wu, Zhenguo Yang, Yun Liu, Yazhou Wang, Gefei Hao. LCM-Captioner: A Lightweight Text-based Image Captioning method with Collaborative Mechanism between Vision and Text. Neural Networks, 2023, 162: 318-329. (第一作者, IF: 9.657, SCI 1, CCF B, Top)' },
                { value: 'Yancheng Sun, Yuling Chen, Peng Wu, Xuewei Wang, Q. Wang*. DRL: Dynamic Rebalance Learning for Adversarial Robustness of UAV with Long-Tailed Distribution, Computer Communications, 2023, 205:14-23. (通讯作者, IF: 5.047, SCI 3)' },
                { value: 'Yuling Chen, Hao Yang, Xuewei Wang, Qi Wang*, and Huiyu Zhou. 2023. GLH: From Global to Local Gradient Attacks with High-Frequency Momentum Guidance for Object Detection. Entropy 25, no. 3: 461. (通讯作者, IF: 2.738, SCI 3)' },
                { value: 'Huang, Yang, Yuling Chen, Xuewei Wang, Jing Yang, and Qi Wang*. Promoting Adversarial Transferability via Dual-Sampling Variance Aggregation and Feature Heterogeneity Attacks. Electronics. 2023, 12(3):767. (通讯作者, IF: 2.690, SCI 3)' },
            ],
            papers2022: [
                { value: 'Zhou, Huizhou, Haoliang Zhao,Qi Wang*, Liang Lei, Gefei Hao, Yusheng Xu, and Zhen Ye. 2022. EMO-MVS: Error-Aware Multi-Scale Iterative Variable Optimizer for Efficient Multi-View Stereo. Remote Sensing, 2022, 14(23):6085. (通讯作者, IF: 5.349, SCI 2, Top)' },
                { value: 'Qi Wang, Jinxiang Lai, Luc Claesen, Zhengguo Yang, Liang lei, Wenyin Liu. A Novel Feature Representation: Aggregating Convolution Kernels for Image Retrieval. Neural Networks, 2020, 130: 1-10. (第一作者, IF: 9.657, SCI 1, CCF B, Top)' },
                { value: 'YANG Jing, LI Bin, LI Shaobo, Qi Wang, YU Liya, HU Jianjun, YUAN Kun. Brain-inspired Continuous Learning: Technology, Application and Future. Journal of Electronics & Information Technology, 2022, 44(5): 1865-1878. (合作作者, SCI 收录)' },

            ],
            papersBefore2021: [
                { value: 'Qi Wang, Jinxiang Lai, Luc Claesen, Zhengguo Yang, Liang lei, Wenyin Liu. A Novel Feature Representation: Aggregating Convolution Kernels for Image Retrieval. Neural Networks, 2020, 130: 1-10. (第一作者, IF: 9.657, SCI 1, CCF B, Top)' },
                { value: 'Qi Wang, Xinchen Liu, Wu Liu, Anan Liu, Wenyin Liu, Tao Mei. MetaSearch: Incremental Product Search via Deep Meta-learning. IEEE Transactions on Image Processing, 2020, 29: 7549-7564. (第一作者, IF: 11.041, SCI 1, CCF A, Top)' },
                { value: 'Qi Wang, Jinxiang Lai, Zhenguo Yang, Xu Kai, Kan Peipei, Liu Wenyin, Lei Liang. Improving Cross-dimensional Weighting Pooling with Multi-scale Feature Fusion for Image Retrieval. Neurocomputing, 2019, 363: 17-26. (第一作者, IF: 5.719, SCI 2, CCF B, Top)' },
                { value: 'Qi Wang, Jingxiang Lai, et al. Beauty Product Image Retrieval Based on Multi-Feature Fusion and Feature Aggregation. In ACMMM, 2018, pp. 2063-2067. (第一作者, CCF A, Top)' },
                { value: 'Chen, Panfeng, et al. Hamming distance encoding multihop relation knowledge graph completion. IEEE Access 8 (2020): 117146-117158.' },
                { value: '肖源源 ; 谢晓尧 ; Application of Novel Gabor-DCNN into RGB-D Face Recognition,International Journal of Network Security, 2020, 22(3): 534-541(期刊论文)( 本人标注: 唯一第一作者 )' },
                { value: '肖源源 ; 谢晓尧 .基于RGB-D生物特征识别的安全认证[J].郑州大学学报(理学版), 2018, 50(04):29-33.DOI:10.13705/j.issn.1671-6841.2017295.' },
                { value: 'Yu Peijia, Zhao Yong, Zhang Jing, Xie xiaoyao. Pedestrian Detection using Multi-Channel Visual Feature Fusion by Learning Deep Quality Model[J]. Journal of Visual Communication and Image Representation, 2019，63（8）：102579.1-102579.7.' },
                { value: 'Peijia Yu, Jing Zhang. An algorithmic method to extend TOPSIS for multiple attribute decision making under intuitionistic fuzzy setting. Journal of Intelligent and Fuzzy Systems, 2014, 26(5) :2315-2333.' },
                { value: 'Yu Peijia, Jiang T T, Zhang J.Long Term Voltage Stability Enhancement by Model Predictive Control[J].Applied Mechanics & Materials, 2012, 157-158:1553-1557.' },
                { value: 'Zhenguo Yang, Qing Li, Haoran Xie, Qi Wang, Wenyin Liu. Learning Representation from Multiple Media Domains for Enhanced Event Discovery. Pattern Recognition, 2021, 110:107640. (合作作者, IF: 7.74, SCI 1, Top)' },
                { value: 'Kai Xu, Qi Wang, et al. Biomedical Named Entity Recognition Based on Dictionary Attention GRU network. Computer Applications and Software, 2020, 37(5). (合作作者, EI)' },
                { value: 'K. Xu, Z. Yang, P. Kang, Q. Wang, and W. Liu. Document-Level Attention-Based BiLSTM-CRF Incorporating Disease Dictionary for Disease Named Entity Recognition. Computers in Biology and Medicine, vol. 108, pp. 122–132, 2019. (合作作者, IF:7.7, SCI 2)' },


            ]
        }
    },
    methods: {
    },
    mounted() {
        // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
        // 子页面加载时触发事件
        this.$emit('changeHeight', '350%');
    },
    computed: {
        totalPapersCount() {
            return this.papers2024.length + this.papers2023.length + this.papers2022.length + this.papersBefore2021.length;
        },
        reverseIndex() {
            return (index, listName) => {
                const listMap = {
                    papers2024: 0,
                    papers2023: this.papers2024.length,
                    papers2022: this.papers2024.length + this.papers2023.length,
                    papersBefore2021: this.papers2024.length + this.papers2023.length + this.papers2022.length
                };
                return this.totalPapersCount - listMap[listName] - index;
            };
        }
    }
}

</script>
<style scoped>
.title-box3 {
    display: flex;
    width: 100%;
    margin-top: 15px;
}

.infos {
    margin-top: 10px;
}
.custom-counter {
  font-weight: bold; /* 加粗 */
}
</style>