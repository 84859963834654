<template>
    <div class="CSNet">
        <!-- 网站简介 -->
        <div class="profile-box">
            <!-- 使用 v-html 渲染 profile 的 HTML 内容，即能实现超链接的跳转 -->
            <p v-html="profile1"></p>
            <p v-html="profile2" style="margin-top: -10px;"></p>
            <p v-html="profile3" style="margin-top: -10px;"></p>
            <p v-html="profile4" style="margin-top: -10px;"></p>

        </div>
        <!-- 图片部分  -->
        <div class="image-box">
            <el-image :src="imgurl" fit="cover"></el-image>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: 'CSNet',
            profile1: `CSNet（<a href="https://csnet.samlab.cn/" target="_blank">https://csnet.samlab.cn/</a>）是一个专注于农业领域提供高效的自动化计数技术，特别是在小麦育种和产量预测方面的应用。
        `,
            profile2: "随着全球人口增长和对粮食需求的增加，提高小麦产量成为确保粮食安全的关键任务之一。然而，传统的手工计数方法不仅效率低，且时间成本高，误差大，严重影响了育种的效率。因此，自动化计数技术的引入，尤其是图像处理和深度学习技术，成为了改善这一状况的有效解决方案。",
            profile3: "我们提出了一种名为CSNet（Count-supervised Network）的新型网络，该网络仅依赖数量信息即可完成小麦穗数的准确计数，无需使用传统的定位监督信息。通过这种方法，我们能够通过小麦的数量特征来进行计数，而不需要依赖昂贵且复杂的标注位置数据，显著降低了标注成本。",
            profile4: "我们的网站旨在通过深度学习和图像处理技术，推动农业自动化进程，提升农业生产的效率与可持续性。随着小麦等作物育种研究的不断深入，我们将继续优化和扩展CSNet，进一步降低农业领域的人工成本，提高育种和生产效率，为全球粮食安全贡献我们的技术力量。",
            imgurl: '../../static/image/webs/CSNet.png',
        }
    },
    methods: {
    },
    mounted() {
        // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
        // 子页面加载时触发事件
        this.$emit('changeHeight1', '118%');
    },
}
</script>

<style scoped>
.CSNet {
    width: 90%;
}

.profile-box {
    position: relative;
    top: 20px;
    width: 100%;
}

.profile-box p {
    font-size: 16px;
    
    text-indent: 2em;
    line-height: 25px
}

.image-box {
    margin-top: 50px;
    margin-left: 10%;
}
</style>