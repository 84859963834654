<template>
   <div>
      <!-- 期刊列表 -->
      <div class="experience-box">
         <div class="experience-infobox">
            <!-- 现在更改为表格展示数据 -->
            <!-- 农业相关的期刊列表 -->
            <div class="plant-table">
               <div class="title-box3">
                  <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                     src="../../static/image/icon.png"></el-image>
                  <h2 style="width: 200px; margin-top: 0px;">植物科学领域</h2>
               </div>
               <el-row :gutter="24">
                  <el-col :span="10">
                     <el-table :data="PlantjournalsLeft" style="width: 406px;" stripe="true" border="true">
                        <el-table-column align="center" prop="name" label="期刊名" width="255" />
                        <el-table-column align="center" prop="IF" label="IF" width="150" />
                     </el-table>
                  </el-col>
                  <el-col :span="12">
                     <el-table :data="PlantjournalsRight" style="width: 451px;" stripe="true" border="true">
                        <el-table-column align="center" prop="name" label="期刊名" width="300" />
                        <el-table-column align="center" prop="IF" label="IF" width="150" />
                     </el-table>
                  </el-col>
               </el-row>
            </div>

            <!-- 计算机相关的期刊列表 -->
            <div class="Computer-table">
               <div class="title-box3">
                  <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                     src="../../static/image/icon.png"></el-image>
                  <h2 style="width: 200px; margin-top: 0px;">计算机科学领域</h2>
               </div>
               <el-row :gutter="24">
                  <el-col :span="10">
                     <el-table :data="ComputerjournalsLeft" style="width: 406px;" stripe="true" border="true">
                        <el-table-column align="center" prop="name" label="期刊名" width="275" />
                        <el-table-column align="center" prop="IF" label="IF" width="130" />
                     </el-table>
                  </el-col>
                  <el-col :span="12">
                     <el-table :data="ComputerjournalsRight" style="width: 451px;" stripe="true" border="true">
                        <el-table-column align="center" prop="name" label="期刊名" width="320" />
                        <el-table-column align="center" prop="IF" label="IF" width="130" />
                     </el-table>
                  </el-col>
               </el-row>
            </div>

            <!-- 综合科学相关的期刊列表 -->
            <div class="Comprehensive-table">
               <div class="title-box3">
                  <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                     src="../../static/image/icon.png"></el-image>
                  <h2 style="width: 200px; margin-top: 0px;">综合科学领域</h2>
               </div>
               <el-row :gutter="24">
                  <el-col :span="10">
                     <el-table :data="ComprehensivejournalsLeft" style="width: 406px;" stripe="true" border="true">
                        <el-table-column align="center" prop="name" label="期刊名" width="275" />
                        <el-table-column align="center" prop="IF" label="IF" width="130" />
                     </el-table>
                  </el-col>
                  <el-col :span="12">
                     <el-table :data="ComprehensivejournalsRight" style="width: 451px;" stripe="true" border="true">
                        <el-table-column align="center" prop="name" label="期刊名" width="320" />
                        <el-table-column align="center" prop="IF" label="IF" width="130" />
                     </el-table>
                  </el-col>
               </el-row>

            </div>
            <!-- 化学科学相关的期刊列表 -->
            <div class="Chemistry-table">
               <div class="title-box3">
                  <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                     src="../../static/image/icon.png"></el-image>
                  <h2 style="width: 200px; margin-top: 0px;">化学领域</h2>
               </div>
               <el-row :gutter="24">
                  <el-col :span="10">
                     <el-table :data="ChemistryjournalsLeft" style="width: 406px;" stripe="true" border="true">
                        <el-table-column align="center" prop="name" label="期刊名" width="275" />
                        <el-table-column align="center" prop="IF" label="IF" width="130" />
                     </el-table>
                  </el-col>
                  <el-col :span="12">
                     <el-table :data="ChemistryjournalsRight" style="width: 451px;" stripe="true" border="true">
                        <el-table-column align="center" prop="name" label="期刊名" width="320" />
                        <el-table-column align="center" prop="IF" label="IF" width="130" />
                     </el-table>
                  </el-col>
               </el-row>
            </div>
            <!-- 工程和科学相关的期刊列表 -->
            <div class="Electrical-table">
               <div class="title-box3">
                  <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                     src="../../static/image/icon.png"></el-image>
                  <h2 style="width: 230px; margin-top: 0px;">工程和电子科学领域</h2>
               </div>
               <el-row :gutter="24">
                  <el-col :span="10">
                     <el-table :data="ElectricaljournalsLeft" style="width: 406px;" stripe="true" border="true">
                        <el-table-column align="center" prop="name" label="期刊名" width="275" />
                        <el-table-column align="center" prop="IF" label="IF" width="130" />
                     </el-table>
                  </el-col>
                  <el-col :span="12">
                     <el-table :data="ElectricaljournalsRight" style="width: 451px;" stripe="true" border="true">
                        <el-table-column align="center" prop="name" label="期刊名" width="320" />
                        <el-table-column align="center" prop="IF" label="IF" width="130" />
                     </el-table>
                  </el-col>
               </el-row>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
export default {
   name: 'Journals',
   data() {
      return {

         PlantjournalsLeft: [
            {
               name: 'Annual Review of Plant Biology',
               IF: '21.3',
            },
            {
               name: 'Trends In Plant Science',
               IF: '17.3',
            },
            {
               name: 'Molecular Plant',
               IF: '17.1',
            },
            {
               name: 'Nature Plants',
               IF: '15.8',
            },
            {
               name: 'Molecular Horticulture',
               IF: '10.6',
            },
            {
               name: 'Plant Biotechnology Journal',
               IF: '10.1',
            },
            {
               name: 'Plant Cell',
               IF: '10',
            },
            {
               name: 'Plant Communications',
               IF: '9.4',
            },
            {
               name: 'Food Chemistry',
               IF: '8.5',
            },
            {
               name: 'Artificial Intelligence in Agriculture',
               IF: '8.2',
            },
         ],
         PlantjournalsRight: [


            {
               name: 'Computers and Electronics in Agriculture',
               IF: '7.7',
            },
            {
               name: 'Information Processing in Agriculture',
               IF: '7.7',
            },
            {
               name: 'Plant Phenomics',
               IF: '7.6',
            },
            {
               name: 'Smart Agricultural Technology',
               IF: '6.3',
            },
            {
               name: 'Plant Journal',
               IF: '6.2',
            },
            {
               name: 'Algricutural Systems',
               IF: '6.1',
            },
            {
               name: 'Crop Journal',
               IF: '6.0',
            },
            {
               name: 'Plant Disease',
               IF: '5.4',
            },
            {
               name: 'European Journal of Agronomy',
               IF: '4.5',
            },
            {
               name: 'Precision Agriculture',
               IF: '4.4',
            },

         ],
         ComputerjournalsLeft: [
            {
               name: 'Foundations and Trends in Machine Learning',
               IF: '65.3',
            },
            {
               name: 'ACM COMPUTING SURVEYS',
               IF: '23.8',
            },
            {
               name: 'Proceedings of the IEEE',
               IF: '23.2',
            },
            {
               name: 'T. Pattern Analysis and Machine Intelligence',
               IF: '20.8',
            },
            {
               name: 'Nature Machine Intelligence',
               IF: '18.8',
            },
            {
               name: 'Computational Visual Media',
               IF: '17.3',
            },
            {
               name: 'Information Fusion',
               IF: '14.7',
            },
            {
               name: 'Computer Science Review',
               IF: '13.3',
            },
            {
               name: 'Applied Computing and Informatics',
               IF: '12.3',
            },
            {
               name: 'IEEE TRANSACTIONS ON EVOLUTIONARY COMPUTATION',
               IF: '11.7',
            },
            {
               name: 'INTERNATIONAL JOURNAL OF COMPUTER VISION',
               IF: '11.6',
            },


         ],
         ComputerjournalsRight: [
            {
               name: 'COMMUNICATIONS OF THE ACM',
               IF: '11.1',
            },
            {
               name: 'T. Fuzzy System',
               IF: '10.7',
            },
            {
               name: 'ARTIFICIAL INTELLIGENCE REVIEW',
               IF: '10.7',
            },
            {
               name: 'IEEE Computational Intelligence Magazine',
               IF: '10.3',
            },
            {
               name: 'T. Neural Networks and Learning Systems',
               IF: '10.2',
            },

            {
               name: 'Pattern Recognition',
               IF: '7.5',
            },
            {
               name: 'Engineering Applications of Artificial Intelligence',
               IF: '7.5',
            },
            {
               name: 'Neural Networks',
               IF: '6.0',
            },
            {
               name: 'Neurocomputing',
               IF: '5.5',
            },
            {
               name: 'International Journal of Intelligent Systems',
               IF: '5.0',
            },
            {
               name: 'Journal of Artificial Intelligence Research',
               IF: '4.5',
            },
            {
               name: 'Machine Learning',
               IF: '4.3',
            },
         ],
         ComprehensivejournalsLeft: [
            {
               name: 'NATURE',
               IF: '54.4',
            },
            {
               name: 'Nature Reviews Methods Primers',
               IF: '50.1',
            },
            {
               name: 'SCIENCE',
               IF: '44.7',
            },
            {
               name: 'eScience',
               IF: '42.9',
            },
            {
               name: 'Nature Methods',
               IF: '36.1',
            },
            {
               name: 'Innovation',
               IF: '33.2',
            },
            {
               name: 'Nature Chemistry',
               IF: '19.2',
            },
            {
               name: 'Science Bulletin',
               IF: '18.8',
            },
            {
               name: 'Nature Machine Intelligence',
               IF: '18.8',
            },
            {
               name: 'National Science Review',
               IF: '16.3',
            },
            {
               name: 'Nature Plants',
               IF: '15.8',
            },
         ],
         ComprehensivejournalsRight: [
            {
               name: 'Nature Communications',
               IF: '14.7',
            },
            {
               name: 'Nature Protocols',
               IF: '13.1',
            },
            {
               name: 'Nature Computational Science',
               IF: '12',
            },
            {
               name: 'GigaScience',
               IF: '11.8',
            },
            {
               name: 'Science Advances',
               IF: '11.7',
            },
            {
               name: 'Journal of Advanced Research',
               IF: '11.4',
            },
            {
               name: 'Journal of Advanced Research',
               IF: '10.4',
            },
            {
               name: 'Engineering',
               IF: '10.1',
            },

            {
               name: 'Proc. Natl. Acad. Sci.',
               IF: '9.4',
            },
            {
               name: 'Cell Systems',
               IF: '9.0',
            },
            {
               name: 'Research',
               IF: '8.5',
            },

         ],
         ChemistryjournalsLeft: [
            {
               name: 'WIRES Comput. Mol. Sci.',
               IF: '16.8',
            },
            {
               name: 'Angewandte Chemie International Edition',
               IF: '16.1',
            },
            {
               name: 'Journal of the American Chemical Society',
               IF: '14.1',
            },
            {
               name: 'Trends in Chemistry',
               IF: '14.0',
            },
            {
               name: 'Chemical Engineering Journal',
               IF: '13.3',
            },
            {
               name: 'ACS Central Science',
               IF: '12.7',
            },

            {
               name: 'Science China-Chemistry',
               IF: '10.4',
            },



         ],
         ChemistryjournalsRight: [

            {
               name: 'CCS Chemistry',
               IF: '9.4',
            },
            {
               name: 'Green Chemistry',
               IF: '9.3',
            },
            {
               name: 'Chemical Science',
               IF: '7.6',
            },
            {
               name: 'Chemical Science',
               IF: '7.6',
            },
            {
               name: 'Journal of Cheminformatics',
               IF: '7.1',
            },
            {
               name: 'Journal of Chemical Information and Modeling',
               IF: '5.6',
            },
            {
               name: 'Chemical Communications',
               IF: '4.3',
            },

         ],
         ElectricaljournalsLeft:[
            {
               name:'PROCEEDINGS OF THE IEEE',
               IF: '23.2',
            },
            {
               name:'IEEE TRANSACTIONS ON PATTERN ANALYSIS AND MACHINE INTELLIGENCE',
               IF: '20.8',
            },
            {
               name:'IEEE TRANSACTIONS ON IMAGE PROCESSING',
               IF: '10.8',
            },


         ],
         ElectricaljournalsRight:[
         {
               name:'IEEE TRANSACTIONS ON FUZZY SYSTEMS',
               IF: '10.7',
            },
            {
               name:'IEEE Transactions on Neural Networks and Learning Systems',
               IF: '10.2',
            },
            {
               name:'IEEE SIGNAL PROCESSING MAGAZINE',
               IF: '9.4',
            },
            {
               name:'T. Computational Biology and Bioinformatics',
               IF: '3.6',
            },
           
         ]
      }
   },
   mounted() {
        this.$emit('changeHeight1', '300%');
        // 将数据分成两部分
        const middleIndex = Math.ceil(this.cooperations.length / 2);
        this.cooperationsLeft = this.cooperations.slice(0, middleIndex);
        this.cooperationsRight = this.cooperations.slice(middleIndex);
    },
}
</script>
<style scoped>
ul {
   list-style-type: none;
}

.infos {
   margin-top: 6px;


}

.title-box3 {
   display: flex;
   width: 100%;
}

.plant-table {
   margin-top: 20px;
}

.Computer-table {
   margin-top: 10px;
}

.Comprehensive-table {
   margin-top: 10px;
}
.Chemistry-table{
   margin-top: 10px;
}
.Electrical-table{
   margin-top: 10px;
}
</style>
