<template>
    <div class="container">
        <div>
            <!-- 顶部导航 -->
            <el-header class="header">
                <div class="logo"><img src="../../static/image/logo.png" alt="logo" class="logo_img">
                    <div class="logo_text">SAMLAB</div>
                </div>
                <div class="nav-box">
                    <el-menu  class="nav-menu" mode="horizontal" router>
                        <el-menu-item index="/">首页</el-menu-item>
                        <el-menu-item @click="handleMenuSelect('/ResearchDirection')"
                            index="/ResearchDirection">研究方向</el-menu-item>
                        <el-menu-item @click="handleMenuSelect('/Members')" index="/Members">人员组成</el-menu-item>
                        <el-menu-item @click="handleMenuSelect('/Projects')" index="/Projects">程序开发</el-menu-item>
                        <el-menu-item @click="handleMenuSelect('/PaperPatent')" index="/PaperPatent">成果展示</el-menu-item>
                        <!-- 这里不要了 -->
                        <!-- <el-menu-item @click="handleMenuSelect('/Awards')" index="/Awards">获奖展示</el-menu-item>  -->
                        <el-menu-item @click="handleMenuSelect('/Blogrolls')" index="/Blogrolls">友情链接</el-menu-item>
                    </el-menu>
                </div>
            </el-header>
            <!-- 根据点击的网页来更改图片的路径 -->
            <el-image :src="imageSrc" alt="image" class="image"></el-image>


        </div>
        <!-- 主要的内容 -->
        <div class="content">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import router from '../router';

export default {
    data() {
        return {
            activeIndex: '1',
            imageSrc: '../../static/image/banner1.png', // 默认图片路径
        };
    },
    methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        handleMenuSelect(index) {
            // 根据点击的菜单项 index 来设置 imageSrc
            switch (index) {
                case '1':
                    this.imageSrc = '../../static/image/banner1.png';
                    break;
                case '/ResearchDirection':
                    this.imageSrc = '../../static/image/banner1.png';
                    break;
                case '/Members':
                    this.imageSrc = '../../static/image/banner2.png';
                    break;
                case '/PaperPatent':
                    this.imageSrc = '../../static/image/banner3.png';
                    break;
                case '/Projects':
                    this.imageSrc = '../../static/image/banner4.png';
                    break;
                case '/Awards':
                    this.imageSrc = '../../static/image/banner5.png';
                    break;
                case '/Blogrolls':
                    this.imageSrc = '../../static/image/banner6.png';
                    break;
                default:
                    this.imageSrc = '../../static/image/banner1.png';
            }
        },
    },
}
</script>

<style scoped>
.container {
    position: relative;
    /* 使子元素可以相对定位 */
    height: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;
    background-color: rgba(255, 255, 255, 0.8);
    /* 半透明背景 */
    position: absolute;
    /* 使导航栏固定在顶部 */
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    /* 确保在图片之上 */

}


.nav-menu {
    flex: 1;
    text-align: right;
    align-items: flex-end;
    background-color: rgb(0 0 0 / 0%);
    /* 半透明背景 */
    border: none;
    /* 去掉菜单的边框 */
    font-size: 16px;
}

.el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: 2px solid #567A54;
}

.nav-menu .el-menu-item {
    background-color: rgb(0 0 0 / 0%);
    /* 菜单项背景透明 */
    color: black;
    /* 文字颜色，根据背景调整 */
}

.nav-menu .el-menu-item.is-active {
    color: #567A54;
    /* 激活项颜色 */
    background-color: rgb(0 0 0 / 0%);
    /* 菜单项背景透明 */

}

.image {
    position: absolute;
    /* top: -80px; */
    width: 100%;
    height: 350px;
    object-fit: cover;
}


.logo {
    padding-left: 10%;
    display: flex;
    align-items: center;
    /* 垂直居中 */
}

.logo_img {
    width: 80px;
    /* margin-top: 5px; */
    margin-right: -20px;
}

.logo_text {
    margin-left: 28px;
    /* 调整此值以增加或减少图片和文字之间的距离 */
    font-family: STHeiti;
    font-size: 25px;
    font-weight: bold;
    /* margin-top: 20px; */
}

.content {
    position: absolute;
    width: 70%;
    height: 1000px;
    background-color: white;
    border: 1px solid white;
    top: 190px;
    left: 15%;
    z-index: 2;
    /* min-width: 200px; */
    /* 增加最小宽度 */
    /* min-height: 1000px; */
    /* 增加最小高度 */
}
</style>