<template>
    <div class="teacher">
        <!-- 头像和基本信息 -->
        <div class="teacher-baseinfo">
            <div>
                <el-image style="height: 200px; width: 166px;" :src="imageUrl"></el-image>
            </div>
            <div class="teacher-baseinfo-infobox">
                <div class="title-box3">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 100px; margin-top: 0px;">基本信息</h2>
                </div>
                <!-- 基本信息 -->
                <div class="teacher-baseinfo-content">
                    <ul>
                        <li v-for="item in basicInfo" :key="item.id" class="infos">
                            <span>{{ item.label }}</span>
                            <span>{{ item.value }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 个人简介 -->
        <div class="profile-box">
            <p>{{ profile }}</p>
        </div>
        <!-- 学习工作经历 -->
        <div class="experience-box">
            <div class="experience-infobox">
                <div class="title-box3">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">学习工作经历</h2>
                </div>
                <ul>
                    <li v-for="item in experiment" :key="item.id" class="infos">
                        <span>{{ item.label }}</span>
                        <span>{{ item.value }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 代表论文 -->
        <div class="paper-box">
            <div class="experience-infobox">
                <div class="title-box3">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">代表论文</h2>
                </div>
                <ul style="list-style-type: disc;">
                    <li v-for="item in papers" :key="item.id" class="infos">
                        <span>{{ item.value }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 科研项目 -->
        <div class="project-box">
            <div class="experience-infobox">
                <div class="title-box3">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">科研项目</h2>
                </div>
                <ol>
                    <li v-for="item in project" :key="item.id" class="infos">
                        <span>{{ item.value }}</span>
                    </li>
                </ol>
            </div>
        </div>
        <!-- 重要奖项 -->
        <div class="award-box">
            <div class="experience-infobox">
                <div class="title-box3">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">重要奖项</h2>
                </div>
                <ol>
                    <li v-for="item in award" :key="item.id" class="infos">
                        <span>{{ item.value }}</span>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'TeacherYuPeiJia',
    data() {
        return {
            imageUrl: '../../static/image/teacher/YuPeiJia.png',
            // 信息数据
            basicInfo: [
                { label: "单位和职称：", value: "贵州大学计算机科学与技术学院特聘副教授" },
                { label: "办公地址：", value: "贵州省贵阳市花溪区贵州大学西校区崇厚楼" },
                { label: "主要研究方向：", value: "人工智能、计算机视觉、智慧农业" },
                { label: "电子邮箱：", value: "pjyu@gzu.edu.cn" },
            ],
            experiment: [
                { label: "2023.07 ~ 现在：", value: "贵州大学，计算机科学与技术学院，特聘副教授" },
                { label: "2017.03 ~ 2018.01：", value: "北京大学深圳研究生院集成微系统重点实验室访问 " },
                { label: "2014.09 ~ 2020.07：", value: "博士，贵州大学 " },

            ],
            papers: [
                { value: "Yu Peijia, Zhao Yong, Zhang Jing, Xie xiaoyao. Pedestrian Detection using Multi-Channel Visual Feature Fusion by Learning Deep Quality Model[J]. Journal of Visual Communication and Image Representation, 2019，63（8）：102579.1-102579.7." },
                { value: "Peijia Yu, Jing Zhang. An algorithmic method to extend TOPSIS for multiple attribute decision making under intuitionistic fuzzy setting. Journal of Intelligent and Fuzzy Systems, 2014, 26(5) :2315-2333." },
                { value: "Yu Peijia, Jiang T T, Zhang J.Long Term Voltage Stability Enhancement by Model Predictive Control[J].Applied Mechanics & Materials, 2012, 157-158:1553-1557." },
                { value: "Yu Peijia , Jiang T , Zhang J .Generators Effect on Voltage Stability Enhancement by Normal Forms of Diffeomorphism[C]//International conference on mechatronics and applied mechanics.2012." },
                { value: "Zhang Jing, Xiong Guojiang, Meng Ke, Yu Peijia, Yao Gang. An improved probabilistic load flow simulation method considering correlated stochastic variables[J]. International Journal of Electrical Power & Energy Systems, 2019: 260-268." }

            ],
            project: [
                { value: "教育部产学合作协同育人项目，工程思维下的信息安全专业Python课程教学改革与资源建设，2024.04-2025.04，主持。" },
                { value: "贵州大学引进人才科研项目，基于深度多模态融合的行人检测究, 2022.01-2024.12，主持。" },
                { value: "国家自然科学基金-面上项目，委托学习的博弈模型及组合安全设计方法，2023.01-2026.12，参与。" },
                { value: "贵州省科技厅-贵州大学联合基金, 铝电解烟气净化系统系列控耗减排绿色环保技术集成与应用, 2024.01-2026.12，参与。" },

            ],
            award: [
                { value: "2023“华为杯”中国研究生数学建模竞赛，2项国家级二等奖" },
            ],
            profile: "余珮嘉，特聘副教授，硕士生导师，目前就职于贵州大学计算机科学与技术学院。长期从事计算机视觉和智慧农业的相关研究，已发表 SCI论文10篇。获10项中国发明专利授权。主持承担了1项教育部课题，1项省级课题，一项校级课题。"
        }
    },
    mounted() {
        // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
        // 子页面加载时触发事件
        this.$emit('changeHeight1', '140%');
    },
}
</script>

<style>
.award-box {
    position: relative;
    top: 20px;
}

.project-box {
    position: relative;
    top: 20px;
}

.paper-box {
    position: relative;
    top: 20px;
}


.experience-box {
    position: relative;
    top: 20px;
}

.profile-box {
    position: relative;
    top: 20px;
    width: 100%;
}

.profile-box p {
    text-indent: 2em;
    line-height: 25px
}

.teacher-baseinfo-content {
    width: 100%;
}

.infos {
    margin-top: 10px;
}

ul {
    list-style-type: none;
}

.title-box3 {
    display: flex;
    width: 100%;
}

.teacher-baseinfo-infobox {
    position: relative;
    left: 33px;
}

.teacher-baseinfo {
    position: relative;
    display: flex;
    top: 30px;
    width: 100%;
}

.teacher {
    width: 89%;
}
</style>