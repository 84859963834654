<template>
    <div class="direction1">
        <div style="color: #BCBCBC; font-size: 40px;position: relative;top: 20px;">{{ TileName }}</div>
        <div class="line"></div>
        <!-- 标题块  -->
        <div class="nameBox">
            <div class="numBox">
                <!-- <h3>1</h3> -->
                <el-image style="height: 25px; width: 25px; margin-top: 5px;"
                    src="../../static/image/iconss/Data.png"></el-image>
            </div>
            <div class="titleBox">
                <h3 style="position: relative;left: 23px;">大数据与人工智能基础研究</h3>
            </div>
        </div>
        <!-- 内容块 -->
        <div>
            <div class="contentBox">
                <p>{{ researchContent }}
                </p>

                <p>{{ researchRoughContent }}
                </p>
            </div>

            <!-- 代表性结果块 -->
            <div class="Representative-Box">
                
                <div class="title-box3">
                    <el-image style="height: 20px; width: 20px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h3 style="width: 300px; margin-top: 0px;">实验室代表性成果：</h3>
                </div>
                <!-- 基本信息 -->
                <ol >
                    <li v-for="item in representativeResults" :key="item.id">{{ item.value }}</li>
                </ol>
            </div>
            <!-- 图片块 -->
            <el-image class="imageBox" style="width: 100%;"  src="../../static/image/AiAndBigdatapng.png"
                fit="cover" />
        </div>


    </div>

</template>



<script>
export default {
    data() {
        return {
            TileName: 'Research Direction',
            researchItems: [
                { id: 1, title: "大数据与人工智能基础研究" }
            ],
            researchContent:"本团队开展的大数据与人工智能基础研究致力于解决多模态感知与大模型构建、多模态视觉任务与基础视觉任务的算法难题，重点攻克少样本学习、增量学习和跨域学习中的泛化与迁移问题，探索强化学习在动态环境中的智能决策能力，并结合知识图谱技术提升模型的知识表达与推理能力。通过多模态数据的深度融合与高效建模，研究旨在推动大规模智能系统的开发和应用，为人工智能在认知智能、数据驱动和知识增强领域的突破提供理论与技术支撑。",
            researchRoughContent:"团队研究内容：多模态感知、多模态大模型、多模态视觉任务、视觉基础任务、少样本学习、增量学习、跨域学习、强化学习、知识图谱、3D视觉。",
            representativeResults:[
                {value:"Qi Wang, Xinchen Liu, Wu Liu, Anan Liu, Wenyin Liu, Tao Mei. MetaSearch: Incremental Product Search via Deep Meta-learning. IEEE Transactions on Image Processing, 2020, 29: 7549-7564. ( IF: 11.041, SCI 1, CCF A, Top)"},
                {value:"Hongyu Deng, Yushan Xie, Qi Wang*, Jianjun Wang, Weijian Ruan, Wu Liu, YongJin Liu. CDKM: Common and Distinct Knowledge Mining Network with Content Interaction for Dense Captioning. IEEE Transactions on Multimedia, 2024. (IF: 7.3, CCF B, SCI 1, Top)"},
                {value:"Qinglei Li, Qi Wang*, Yongbin Qin, Xinyu Dong, Xingcai Wu, Shiming Chen, Wu Liu, Member, Yong-Jin Liu, Jiebo Luo. DRC: Discrete Representation Classifier with Salient Features via Fixed-prototype. IEEE Transactions on Circuits and Systems for Video Technology, 2024. (IF: 8.4, CCF B, SCI 1, Top)"},
                {value:"Qi Wang, JianJun Wang, Hongyu Deng, Xue Wu, Yazhou Wang, Gefei Hao. AA-Trans: Core Attention Aggregating Transformer with Information Entropy Selector for Fine-grained Visual Classification. Pattern Recognition, 2023, 140:109547. (IF: 8.518, SCI 1, CCF B, Top))"},
                {value:"Jinxiang Zhu#, Qi Wang#*, et al. FSNA: Few-Shot Object Detection via Neighborhood Information Adaption and All Attention. IEEE Transactions on Circuits and Systems for Video Technology, 2024. (IF: 8.4, CCF B, SCI 1, Top)"},
            ]
        }
    },
    mounted() {
        // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
        // 子页面加载时触发事件
        this.$emit('changeHeight1', '140%');
    },
}
</script>
<style scoped>
.imageBox {
    position: relative;
    top: 60px;
    /* left: 5%; */
}

.contentBox {
    position: relative;
    top: 60px;

}

.contentBox p {
    font-weight: 500;
    text-align: left;
    text-indent: 2em;
    padding: 0px;
    margin: 0px;
    line-height: 30px;

}
.Representative-Box{
    position: relative;
    top: 60px;
    width: 95%;
    /* left: 1%; */

}

.Representative-Box ol {
    list-style-type: decimal;
    /* padding: 0px;
    margin: 0px; */
}

.Representative-Box ol li {
    line-height: 24px;
}


.nameBox {
    display: flex;
    position: relative;
    top: 49px;

}

.direction1 {
    width: 90%;
}

.line {
    position: relative;
    top: 30px;
    bottom: 32.5px;
    border: 0.1px solid #BCBCBC;
    width: 100%;
}

.numBox {
    width: 67px;
    height: 67px;
    background-color: #D4DFEB;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    /* color: #2448FF; */

}

.titleBox {
    height: 67px;
    width: 100%;
    font-size: 17px;
    color: #2E5A2D;
    background-color: #F7F9FA;
    justify-content: center;
    align-items: center;

}
.title-box3 {
    display: flex;
    width: 100%;
    margin-bottom: -30px;
    margin-top: 10px;
}
</style>