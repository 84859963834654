<template>
    <div class="award">
        <!-- 国家级学科竞赛奖励块 -->
        <div>
            <div class="title-box3">
                <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                    src="../../static/image/icon.png"></el-image>
                <h2 style="width: 250px; margin-top: 0px;">国家级学科竞赛奖励：</h2>
            </div>
            <ul style="list-style-type: disc;">
                <li v-for="item in nationaldiSciplineAwards" :key="item.id" class="infos">
                    <span>{{ item.value }}</span>
                </li>
            </ul>
        </div>
        <!-- 国家奖学金块 -->
        <div>
            <div class="title-box3">
                <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                    src="../../static/image/icon.png"></el-image>
                <h2 style="width: 250px; margin-top: 0px;">国家奖学金：</h2>
            </div>
            <ul style="list-style-type: disc;">
                <li v-for="item in NationalScholarship" :key="item.id" class="infos">
                    <span>{{ item.value }}</span>
                </li>
            </ul>
        </div>
        <!-- 贵州大学特等奖学金块 -->
        <div>
            <div class="title-box3">
                <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                    src="../../static/image/icon.png"></el-image>
                <h2 style="width: 250px; margin-top: 0px;">贵州大学特等奖学金：</h2>
            </div>
            <ul style="list-style-type: disc;">
                <li v-for="item in SpecialScholarshipGuizhouUniversity" :key="item.id" class="infos">
                    <span>{{ item.value }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            nationaldiSciplineAwards: [
                { value: '作品 《劳动用工大数据要素化助力农民工就业新途径》在第十八届“挑战杯”全国大学生课外学术科技作品竞赛“揭榜挂帅“专项赛中荣获  最具人气作品' },
                { value: '第八届中国国际“互联网+”大学生创新创业大赛贵州大学校赛 优秀指导教师(团队)奖' },
                { value: '作品《劳动用工大数据要素化助力农民工就业新途径》在第十八届“挑战杯”全国大学生课外学术科技作品竞赛“揭榜挂帅”专项赛中荣获 特等奖' },
                { value: '项目《“助农识病”--农作物病虫害智能识别与用药推荐工具》在第十八届“挑战杯”全国大学生课外学术科技作品竞赛贵州省终审决赛中荣获一等奖' },
                { value: '项目《基于非欧几里得空间的CNN 通用决策边界理论》在第十八届“挑战杯”全国大学生课外学术科技作品竞赛贵州省终审决赛中荣获一等奖' },
                { value: '项目《职通官--基于多源异构数据融合技术农民工数字画像分析与应用》第八届中国国际“互联网+”大学生创新创业大赛贵州省赛“高教主赛用道”中荣获铜奖' },
                { value: '项目《基于多源异构数据融合的贵州省农民工数字画像分析与应用》在第十三届“挑战杯”贵州省大学生创业计划竞赛中荣获:二等奖' },
                { value: '中国大学生工程实践与创新能力大赛贵州省选拔赛 特等奖' },
                { value: '指导项目:《职通官--基于多源异构数据融合技术农民工数字画像分析与应用》在第八届中国国际“互联网+”大学生创新创业大赛贵州大学校赛 优秀指导教师(团队)奖' },


            ],
            NationalScholarship: [
                { value: '吴晨阳同学荣获2022至2023 学年度本专科生国家奖学金。' },
                { value: '董新宇同学荣获2023年硕士研究生国家奖学金。' },
                { value: '李耀羲同学荣获2024年硕士研究生国家奖学金。' },
                { value: '吴兴财同学荣获2024年博士研究生国家奖学金。' },


            ],
            SpecialScholarshipGuizhouUniversity: [
                { value: '李青雷同学:在2022-2023 学年度中，学习刻苦，成绩优秀，荣获贵州大学硕士特等奖学金' },
                { value: '赵克君同学:在 2022-2023 学年度中，学习刻苦，成绩优秀，荣获贵州大学硕士特等奖学金' },
            ]
        }
    },
    methods: {
    },
    mounted() {
        // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
        // 子页面加载时触发事件
        this.$emit('changeHeight', '110%');
    },
}
</script>
<style scoped>
.award {
    width: 90%;
}

.title-box3 {
    display: flex;
    width: 100%;
    margin-top: 15px;
}

.infos {
    margin-top: 10px;
}
</style>