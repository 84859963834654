<template>
    <div class="LLRL">
        <!-- 网站简介 -->
        <div class="profile-box">
            <!-- 使用 v-html 渲染 profile 的 HTML 内容，即能实现超链接的跳转 -->
            <p v-html="profile1"></p>
            <p v-html="profile2" style="margin-top: -10px;"></p>
            <p v-html="profile3" style="margin-top: -10px;"></p>
            <p v-html="profile4" style="margin-top: -10px;"></p>

        </div>
        <!-- 图片部分  -->
        <div class="image-box">
            <el-image :src="imgurl" fit="cover"></el-image>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: 'LLRL',
            profile1: `LLRL（<a href="http://llrl.samlab.cn/" target="_blank">http://llrl.samlab.cn/</a>）是一个专注于推动植物病害诊断领域的技术创新，特别是在植物叶片病害严重程度评估方面，为农业生产的精准化管理提供先进的解决方案。作物病害严重程度的准确评估对于优化农药使用量、提高作物产量具有至关重要的意义。然而，传统方法通常依赖全局感知学习，在复杂背景下容易将非病变区域误判为病变区域，导致诊断精度下降，制约了实际应用效果。
        `,
            profile2: "为了解决这一问题，我们提出了一种全新的基于图像生成的定位引导病变表示学习方法（LLRL），专注于植物叶片病害严重程度的精准评估。该方法通过三大核心网络模块的协同工作，实现了对病害病变区域的准确聚焦和严重程度的精确分类：(1)图像生成网络（IG-Net），(2)定位引导病变表示学习网络（LGR-Net），(3)层次化病变融合评估网络（HLFA-Net）",
            profile3: "此外，我们还构建了一个包含苹果、马铃薯和番茄三种植物叶片病害的图像数据集，总计12,098张高质量图片，用于验证我们方法的有效性。实验结果表明，LLRL方法在病害严重程度评估的准确性上显著优于现有分类模型，精度提升至少达到1%，充分体现了LLRL方法在精准农业领域的优势。",
            profile4: "通过我们的网站，您可以深入了解这一创新方法的细节，同时获取相关数据集、模型和研究资源。无论您是从事植物病害研究，还是寻求实际应用的高效解决方案，我们的平台都将为您提供全面的技术支持。加入我们，一起推动植物病害诊断与评估的智能化与精准化，共同助力现代农业的可持续发展！",
            imgurl: '../../static/image/webs/LLRL.png',
        }
    },
    methods: {
    },
    mounted() {
        // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
        // 子页面加载时触发事件
        this.$emit('changeHeight1', '125%');
    },
}
</script>

<style scoped>
.LLRL {
    width: 90%;
}

.profile-box {
    position: relative;
    top: 20px;
    width: 100%;
}

.profile-box p {
    font-size: 16px;
    
    text-indent: 2em;
    line-height: 25px
}

.image-box {
    margin-top: 50px;
}
</style>