<template>
    <div class="teacher">
        <!-- 头像和基本信息 -->
        <div class="teacher-baseinfo">
            <div>
                <el-image style="height: 200px; width: 166px;" :src="imageUrl"></el-image>
            </div>
            <div class="teacher-baseinfo-infobox">
                <div class="title-box2">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 100px; margin-top: 0px;">基本信息</h2>
                </div>
                <!-- 基本信息 -->
                <div class="teacher-baseinfo-content">
                    <ul>
                        <li v-for="item in basicInfo" :key="item.id" class="infos">
                            <span>{{ item.label }}</span>
                            <span>{{ item.value }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 个人简介 -->
        <div class="profile-box">
            <p>{{ profile }}</p>
        </div>
        <!-- 学习工作经历 -->
        <div class="experience-box">
            <div class="experience-infobox">
                <div class="title-box2">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">学习工作经历</h2>
                </div>
                <ul>
                    <li v-for="item in experiment" :key="item.id" class="infos">
                        <span>{{ item.label }}</span>
                        <span>{{ item.value }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 代表论文 -->
        <div class="paper-box">
            <div class="experience-infobox">
                <div class="title-box2">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">代表论文</h2>
                </div>
                <ul style="list-style-type: disc;">
                    <li v-for="item in papers" :key="item.id" class="infos">
                        <span>{{ item.value }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 科研项目 -->
        <div class="project-box">
            <div class="experience-infobox">
                <div class="title-box2">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">科研项目</h2>
                </div>
                <ol >
                    <li v-for="item in project" :key="item.id" class="infos">
                        <span>{{ item.value }}</span>
                    </li>
                </ol>
            </div>
        </div>
        <!-- 重要奖项 -->
        <!-- <div class="award-box">
            <div class="experience-infobox">
                <div class="title-box">
                    <el-image style="height: 30px; width: 30px; margin-top: 5px;"
                        src="../../static/image/icon.png"></el-image>
                    <h2 style="width: 150px; margin-top: 0px;">重要奖项</h2>
                </div>
                <ol >
                    <li v-for="item in award" :key="item.id" class="infos">
                        <span>{{ item.value }}</span>
                    </li>
                </ol>
            </div>
        </div> -->
    </div>
</template>

<script>

export default {
    name: 'TeacherChenPanFeng',
    data() {
        return {
            imageUrl: '../../static/image/teacher/ChenPanFeng.png',
            // 信息数据
            basicInfo: [
                { label: "单位和职称：", value: "校聘副教授，硕导" },
                { label: "办公地址：", value: "贵州省贵阳市花溪区贵州大学西区崇厚楼713-1室" },
                { label: "主要研究方向：", value: "人工智能、计算机视觉、智慧农业、知识图谱" },
                { label: "电子邮箱：", value: "pfchen@gzu.edu.cn" },
            ],
            experiment: [
                { label: "2022.03 ~ 现在：", value: "贵州大学，工作" },
                { label: "2017.09 ~ 2021.12：", value: "贵州大学 软件工程 博士"},
                { label: "2015.10 ~ 2017.08：", value: "黔南州中级人民法院 网络工程师"},
                { label: "2012.05 ~ 2015.09：", value: "黔南州罗甸县教育局 教研员"},
                { label: "2008.09 ~ 2011.06：", value: "华中师范大学 教育技术学 硕士"},
                { label: "2003.09 ~ 2007.06：", value: "三峡大学 教育技术学  学士 "},

            ],
            papers: [
                { value: "Chen, Panfeng, et al. Qlogice: Quantum logic empowered embedding for knowledge graph completion. Knowledge-Based Systems 239 (2022): 107963." },
                { value: "Chen, Panfeng, et al. Hamming distance encoding multihop relation knowledge graph completion. IEEE Access 8 (2020): 117146-117158." },
                { value: "Dong, Xinyu, et al. PlantPAD: a platform for large-scale image phenomics analysis of disease in plant science.Nucleic Acids Research 52.D1 (2024): D1556-D1568." },
                { value: "Zhao, Fuyong and Li, Yuyang and Wang, Yanhao and Li, Hui and Chen, Mei and Chen, Panfeng and Sun,, et al. Pulsar Candidate Classification with Multimodal Large Language Models. Neurips 2024 Workshop Foundation Models for Science: Progress, Opportunities, and Challenges." },
                { value: "Guojun Chen, Panfeng Chen(通讯), et al. EMGE: Entities and Mentions Gradual Enhancement with semantics and connection modeling for document-level relation extraction. Knowledge-Based Systems, 2024.336:112777." },
                { value: "Guojun Chen, Panfeng Chen(通讯), et al. Relation Semantic Guidance and Entity Position Location for Relation Extraction. Data Science and Engineering, 2024. doi: 10.1007/ s41019-024-00268-5." },
                { value: "Yaoxi Li, Xingcai Wu, Qi Wang, Zhixun Pei, Kejun Zhao, Panfeng Chen, Gefei Hao. CSNet: A Count-Supervised Network via Multiscale MLP-Mixer for Wheat Ear Counting[J]. Plant Phenomics, 2024, 6: 0236." },
                { value: "Xiaomin Yu, Yisong Wang, Jin Qin, Panfeng Chen. A Q-based policy gradient optimization approach for Doudizhu[J]. Applied Intelligence, 2023, 53(12): 15372-15389." },
                { value: "张芊,陈攀峰,冯林坤,等.PeMeBench：中文儿科医疗问答基准测试方法[J].大数据,2024,10(05):28-44." },
                { value: "王畅， 马丹， 许华容， 陈攀峰,等.SA-MGKT:基于自注意力融合的多图知识追踪方法[J].华东师范大学学报(自然科学版),2024,(05):20-31." },


                
            ],
            project:[
                {value : "贵州大学，贵州大学高层次人才平台建设项目， 贵大人基合字(2022)47号，民族医药知识图谱关键技术研究，2023.1至2025.1，5万，在研，主持。"},
                {value : "上海市大数据管理系统工程研究中心，中医药大数据知识工程化研究，2023.10-2024.10，2万，在研，主持。"},
                {value : "贵州省科技厅，自然科学基础研究，黔科合基础-ZK[2024]一般099，基于人工智能生成内容(AIGC)与知识图谱(KG)的中医药辩证论治关键技术研究，2024.3至2027.4，10万，在研，主持。"},
                {value : "中国21世纪议程管理中心，国家重点研发项目，2023YFC3341200，教育大数据驱动的个性化学习关键技术研究与示范应用, 2024-01 至 2026-12,3200万元, 在研, 参与。"},
                {value : "贵州省科技厅，科技重大专项，黔科合重大专项字[2024]003，面向公共大数据的可信大模型关键技术研究与应用，2024-01-01至2026-12-31，1460万元，在研，参与。"},

            ],
            profile: "研究方向为知识表示与推理，聚焦于知识增强的大模型研究，致力于用知识图谱缓解大模型的幻觉问题，交叉方向为植物病虫害精准防治。以第一作者或合作者公开发表SCI论文10余篇，参与国家重点研发、国家自然科学基金、贵州省重点研发项目4项，主持贵州省科技厅项目1项。"
        }
    },
    mounted() {
        // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
        // 子页面加载时触发事件
        this.$emit('changeHeight1', '175%');
    },
}
</script>

<style>
.award-box{
    position: relative;
    top: 20px;
}
.project-box {
    position: relative;
    top: 20px;
}

.paper-box {
    position: relative;
    top: 20px;
}


.experience-box {
    position: relative;
    top: 20px;
}

.profile-box {
    position: relative;
    top: 20px;
    width: 100%;
}

.profile-box p {
    text-indent: 2em;
    line-height: 25px
}

.teacher-baseinfo-content {
    width: 100%;
}

.infos {
    margin-top: 10px;
}

ul {
    list-style-type: none;
}

.title-box2 {
    display: flex;
    width: 100%;
}

.teacher-baseinfo-infobox {
    position: relative;
    left: 33px;
}

.teacher-baseinfo {
    position: relative;
    display: flex;
    top: 30px;
    width: 100%;
}

.teacher {
    width: 89%;
}
</style>