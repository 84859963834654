<template>
    <div class="PlantDisease">
        <!-- 网站简介 -->
        <div class="profile-box">
            <!-- 使用 v-html 渲染 profile 的 HTML 内容，即能实现超链接的跳转 -->
            <p v-html="profile1"></p>
            <p v-html="profile2" style="margin-top: -10px;"></p>
            <p v-html="profile3" style="margin-top: -10px;"></p>
            <p v-html="profile4" style="margin-top: -10px;"></p>

        </div>
        <!-- 图片部分  -->
        <div class="image-box">
            <el-image :src="imgurl" fit="cover"></el-image>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: 'PlantDRAW',
            profile1: `PlantDRAW（<a href="https://plantdraw.samlab.cn/" target="_blank">https://plantdraw.samlab.cn/</a>）是一个专注于植物病害识别与分析提供一个快速、精准的图像识别工具，帮助农业从业者、植物学家和园艺师更高效地诊断和管理植物病害。利用先进的深度学习模型，我们能够准确识别各种植物病害，为您提供快速且可靠的诊断结果。
        `,
            profile2: "我们的系统涵盖了多种植物物种，无论是水稻、小麦、蔬菜还是果树，我们都能为您提供全面的病害检测服务。针对不同植物的病害，我们提供专门的诊断工具，确保每个植物物种都能得到精准的检测与分析。除了提供定性病害诊断，我们还为您提供病害的定量评估，帮助您了解病害对植物生长的具体影响。这一功能能帮助农民和种植者更好地判断病害的严重程度，及时采取措施，避免病害蔓延。我们网站的设计考虑到了用户的体验，提供一个简单而直观的操作界面，用户可以轻松上传植物图片并快速获取专业的病害诊断结果。无论您是农业专家还是普通用户，都能轻松上手，快速得到所需的信息。",
            profile3: "为了应对这些挑战，我们提出了一系列基于植物病害图像的预训练模型。这些模型专为植物病害诊断任务设计，包含植物病害识别、病害检测、病害分割以及其他相关子任务。我们的实验表明，这些植物病害预训练模型在诊断精度和训练效率方面均优于传统的通用预训练模型。它们不仅能够显著提升植物病害诊断的准确性，还能减少训练时间，为用户提供高效可靠的解决方案。",
            profile4: "我们致力于通过人工智能技术，提高农业病害诊断的效率和准确性，减少人工检测的误差和时间成本。通过我们的平台，您不仅能及时发现植物病害，还能得到科学的病害管理建议，从而提高作物的产量和质量。我们希望通过这项技术，为全球农业的可持续发展和食品安全贡献力量。无论您是农场主、园艺师，还是植物研究人员，我们的平台都将成为您在植物病害防控中的得力助手。加入我们，让我们一起迈向更加智能和高效的农业未来！",
            imgurl: '../../static/image/webs/PlantDisease.png',
        }
    },
    methods: {
    },
    mounted() {
        // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
        // 子页面加载时触发事件
        this.$emit('changeHeight1', '112%');
    },
}
</script>

<style scoped>
.PlantDisease {
    width: 88%;
}

.profile-box {
    position: relative;
    top: 20px;
    width: 100%;
}

.profile-box p {
    font-size: 16px;
    
    text-indent: 2em;
    line-height: 25px
}

.image-box {
    margin-top: 50px;
}
</style>