<template>
  <div class="direction1">
    <div style="color: #BCBCBC; font-size: 40px;position: relative;top: 20px;">{{ TileName }}</div>
    <div class="line"></div>
    <!-- 标题块  -->
    <div class="nameBox">
      <div class="numBox">
        <!-- <h3>1</h3> -->
        <el-image style="height: 25px; width: 25px; margin-top: 5px;"
          src="../../static/image/iconss/SmartPlant.png"></el-image>
      </div>
      <div class="titleBox">
        <h3 style="position: relative;left: 23px;">植物智慧医学</h3>
      </div>
    </div>
    <!-- 内容块 -->
    <div>
      <div class="contentBox">
        <p>
          植物智慧医学是一项融合植物科学、人工智能和农药学的交叉研究领域，旨在结合大数据分析和智能算法，探索植物病害智能诊断的潜力。研究内容包括植物活性物质的高通量筛选与作用机理解析、基于知识图谱的植物药效关联挖掘、智能优化药物配方，以及利用多模态感知技术实现植物病害表型的精准判定。通过构建数据驱动的人工智能模型，植物智慧医学为开发个性化病害诊断和用药方案提供了新思路，助力现代农业绿色化转型。-感知算法-用药决策”三方面着手，构建作物病害多模态智能诊断技术解决上述问题。
        </p>
        <p>
          作物病害是农业生产面临的主要挑战之一，错误的诊断结果以及随意使用农药将加重病害的威胁以致更大经济损失，现有人工评估极难准确得到病害种类和病害等级，进而影响植物病害的治疗。数字化和智能化是解决病虫害防治的有力武器。目前实验室从“数据构建-感知算法-用药决策”三方面着手，构建作物病害多模态智能诊断技术解决上述问题。（1）人工智能技术已广泛应用于不同学科领域，尤其在智慧农业方面。然而，在AI应用于垂直领域时，数据的收集与处理是首要问题。目前全球病害数字化诊断数据分散、信息注释不足且数据多样性不够，限制了多学科交叉研究的发展。（2）作物病害数字化诊断数据存在样本量不平衡、内容差异大及多模态感知困难等问题。实验室提出了针对不同农业环境的作物病害多模态感知与精准治疗决策算法，从而提升病害数字化诊断的准确性。（3）现有病害数字化诊断存在无法解决田间病害的早期检测、多病害识别、病害的精准用药等问题，实验室集成了数字化病害诊断和用药平台，解决病害数字化智能诊断到治疗决策的一体化技术。-感知算法-用药决策”三方面着手，构建作物病害多模态智能诊断技术解决上述问题。
        </p>
        <p>
          团队研究内容：复杂田间环境下的作物病害智能诊断、农药数字化和智能化、作物病害诊断大模型、植物病害诊断用药一体化。
        </p>

      </div>

      <!-- 代表性结果块 -->
      <div class="Representative-Box">

        <div class="title-box3">
          <el-image style="height: 20px; width: 20px; margin-top: 5px;" src="../../static/image/icon.png"></el-image>
          <h3 style="width: 300px; margin-top: 0px;">实验室代表性成果：</h3>
        </div>
        
        <ol>
          <li v-for="item in representativeResults" :key="item.id">{{ item.value }}</li>
        </ol>
      </div>

      <el-image class="imageBox" style="width: 100%;" src="../../static/image/Direction2.2.png" fit="cover" />
    </div>


  </div>

</template>



<script>
export default {
  data() {
    return {
      TileName: 'Research Direction',
      researchItems: [
        { id: 1, title: "植物智慧医学" }
      ],

      representativeResults: [
        { value: "Xinyu Dong#, Kejun Zhao#, Qi Wang#*, Xingcai Wu#, Yuanqin Huang#, et al. PlantPAD: a platform for large-scale image phenomics analysis of disease in plant science. Nucleic Acids Research. 2023, gkad917. ( IF: 14.9, SCI 2, Top)" },
        { value: "Kejun Zhao, Xingcai Wu, Yuanyuan Xiao, Sijun Jiang, Peijia Yu, Yazhou Wang, Qi Wang*. PlanText: Gradually Masked Guidance to Align Image Phenotype with Trait Description for Plant Disease Texts. Plant Phenomics, 2024. (IF: 7.6, SCI 1, Top)" },
        { value: "Qianding Huang, Xingcai Wu, Qi Wang*, Xinyu Dong, Liang Lei, Xue Wu, Yangyang Gao, and Gefei Hao. Knowledge Distillation Facilitates the Lightweight and Efficient of Plant Diseases Detection Model. Plant Phenomics, 2023, 5:0062. (IF: 6.961, SCI 1, Top)" },
        { value: "Xinyu Dong, Qi Wang*, Qianding Huang, Qinglong Ge, Kejun Zhao, Xingcai Wu, Xue Wu, Liang Lei, and Gefei Hao. PDDD-PreTrain: A series of common-used pre-trained models support image-based plant disease diagnosis. Plant Phenomics, 2023, 5:0054. (IF: 6.961, SCI 1, Top)" },
        { value: "Xue Wu, Hongyu Deng, Qi Wang*, YangYang Gao, Liang Lei, Ge-Fei Hao. Meta-learning Shows Great Potential in Plant Diseases Recognition Under Few Available Samples. Plant Journal. 2023, 03. (IF: 7.091, SCI 1, Top)" },
      ]
    }
  },
  mounted() {
        // 主要用于根据自己网页的内容长度来改变左边的el-aside的高度
        // 子页面加载时触发事件
        this.$emit('changeHeight1', '170%');
    },
}
</script>
<style scoped>
.title-box3 {
    display: flex;
    width: 100%;
    margin-bottom: -30px;
    margin-top: 10px;
}

.Representative-Box{
    position: relative;
    top: 60px;
    width: 95%;
    /* left: 1%; */

}

.Representative-Box ol {
    list-style-type: decimal;
    /* padding: 0px;
    margin: 0px; */
}

.Representative-Box ol li {
    line-height: 24px;
}

.imageBox {
  position: relative;
  top: 80px;
  /* left: 5%; */
}

.contentBox {
  position: relative;
  top: 60px;

}

.contentBox p {
  font-weight: 500;
  text-align: left;
  text-indent: 2em;
  padding: 0px;
  margin: 0px;
  line-height: 30px;
}

.nameBox {
  display: flex;
  position: relative;
  top: 49px;

}

.direction1 {
  width: 90%;
}

.line {
  position: relative;
  top: 30px;
  bottom: 32.5px;
  border: 0.1px solid #BCBCBC;
  width: 100%;
}

.numBox {
  width: 67px;
  height: 67px;
  background-color: #D4DFEB;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  /* color: #2448FF; */

}

.titleBox {
  height: 67px;
  width: 100%;
  font-size: 17px;
  color: #2E5A2D;
  background-color: #F7F9FA;
  justify-content: center;
  align-items: center;

}
</style>